import React, { useEffect, useState } from "react";
import { Table, Button } from "antd";
import { userConfig } from "@/app/ams/config/user/userConfig";
import Statistics from "@/app/ams/components/Statistics";
import Detail from "@/app/ams/components/Detail";
import ToolBar from "@/app/ams/components/ToolBar";
import { useAmsContext } from '@/app/ams/context/AmsContext';
import { Link } from "react-router-dom";
import { HomeOutlined, PlusOutlined } from "@ant-design/icons";
import { statisticsConfig } from "@/app/ams/config/statistics/statisticsConfig";
import moment from "moment";


export default function User() {
  const { showForm, setShowForm, selectedUser, setSelectedUser, users, fetchUsers, setUsers } = useAmsContext();
  const [showOrderManagement, setShowOrderManagement] = useState(false);
  const tableData = Array.isArray(users) ? users : [];
  const totalUsers = tableData.length || 0;
  const availableUsers = tableData.filter(user => user.status === 'On Sale').length || 0;
  const lowStockWarnings = tableData.filter(user => user.status === 'Low Stock').length || 0;
  const onPromotion = tableData.filter(user => user.status === 'On Promotion').length || 0;
  const drafts = tableData.filter(user => user.status === 'Draft').length || 0;

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    console.log('users', users);
  }, [users]);

  const handleShowForm = () => {
    setShowForm(true);
  };

  const handleRowClick = (record) => {
    if (selectedUser?.key !== record.key) {
      const userWithImage = {
        ...record,
        imageUrl: record.picture || '/images/user.png',
        name: record.nickname || `${record.first_name || ''} ${record.last_name || ''}`.trim() || 'Unset',
        description: `
          電話: ${record.phone || 'Unset'}
          Email: ${record.email || 'Unset'}
          等級: Level ${record.level || 0}
          累積消費: $${record.total_spending?.toLocaleString() || 0}
          訂單數: ${record.order_count || 0}
        `,
        notification: `
          加入日期: ${moment(record.created_at).format('YYYY-MM-DD')}
          最後登入: ${record.last_login ? moment(record.last_login).format('YYYY-MM-DD HH:mm') : 'Unset'}
        `,
        specs: [
          {
            name: '服務訂單',
            price: record.service_orders?.length || 0
          },
          {
            name: '商品訂單',
            price: record.product_orders?.length || 0
          }
        ],
        status: record.enabled ? '開放' : '關閉'
      };
      setSelectedUser(userWithImage);
    }
  };

  useEffect(() => {
    if (users?.length > 0 && !selectedUser) {
      const firstUser = users[0];
      handleRowClick(firstUser);
    }
  }, [users, selectedUser]);

  const tableColumns = userConfig.columns.map(column => ({
    ...column,
    onCell: (record) => ({
      onClick: () => {
        if (column.onCellClick) {
          column.onCellClick(record, {
            users,
            setUsers,
            setShowOrderManagement,
            setSelectedUser
          });
        }
      }
    })
  }));


  
  const BreadcrumbNav = () => (
    <div className="flex flex-col space-y-2 mb-4 p-4">
      <div className="text-lg font-bold">服務管理</div>

      <div className="flex items-center text-sm">
        <Link to="/" className="text-green-600">
          <HomeOutlined className="mr-1" />
        </Link>
        <span className="mx-2 text-gray-500">/</span>
        <span
          className="text-green-600 cursor-pointer"
          onClick={() => window.location.href = '/service'}
        >
          服務管理
        </span>
        <span className="mx-2 text-gray-500">/</span>
        <span className="text-gray-500">新增服務</span>
      </div>
    </div>
  );



  return (
    <div className="flex flex-col items-center justify-start w-full h-screen overflow-auto">
      <ToolBar />
      <div className="flex flex-col w-full h-[calc(100vh-64px)]">

        <div className="flex-1 flex overflow-auto">
          {showForm ? (
            <>
              <div className="flex w-full space-x-4 overflow-auto">
                <div className="flex-1 overflow-hidden">
                  <BreadcrumbNav />
                  {/* <Form pancoConfig={pancoConfig} formLogic={formLogic} useFormScroll={useFormScroll} /> */}
                </div>
                <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
                  <Detail />
                </div>
              </div>
            </>
           ) : (
            <>
              <div className="flex w-full overflow-auto bg-gray-50">
                <div className="flex-1 flex flex-col shadow min-w-0 overflow-auto h-full p-4">
                  <div className="flex justify-between items-center mb-4">
                    <div className="text-lg font-bold">{userConfig?.page?.[0]?.title || "服務管理"}</div>
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={handleShowForm}
                      className="bg-[#ADDEAC] hover:bg-[#45a049] text-black hover:!text-black invisible"
                    >
                      {userConfig?.page?.[0]?.addButton || "新增服務"}
                    </Button>
                  </div>
                  <Statistics total={totalUsers} available={availableUsers} lowStockWarnings={lowStockWarnings} onPromotion={onPromotion} drafts={drafts} totalTitle={statisticsConfig?.statistics?.user?.total?.title || "總用戶數"} availableTitle={statisticsConfig?.statistics?.user?.available?.title || "上架用戶數"} />

                  <Table 
                    columns={tableColumns} 
                    dataSource={tableData}
                    scroll={{ x: 2300 }}
                    className="[&_.ant-table-thead>tr>th]:bg-[#E0F0E0]"
                    onRow={(record) => ({
                      onClick: () => handleRowClick(record),
                      className: record.key === (selectedUser?.key ?? '') ? "bg-gray-100" : "",
                    })}
                    rowClassName={(record) => `cursor-pointer ${record.key === (selectedUser?.key ?? '') ? "bg-gray-100" : ""}`}
                  />
                </div>
                <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
                  <Detail 
                    data={selectedUser} 
                    type="user"
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
