import moment from 'moment';
import { Input, Button, Tag } from 'antd';
import { callApi } from '@/utils';

const stag5Config = {
    page: [
        {
            title: "Service Management",
            addButton: "Add Service",
            information: "Basic Information",
            specification: "Service Specifications",
            discount: "Discounts and Offers",
            publish: "Service Deployment",
        }
    ],
    onPublish: (formData, { saveServiceData }) => {
        // 保存表單數據到 context
        saveServiceData(formData);
    },
    columns: ({ setShowOrderManagement, setSelectedService, services, setServices }) => [
        {
          title: "RECOMMEND",
          width: "10%",
          fixed: "left",
          dataIndex: "recommendation",
          align: "center",
          
          render: (value, record) => (
            <Input
              type="text"
              style={{ width: '60px' }}
              value={value || ''}
              
              className="[&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none [&]:MozAppearance-textfield"
            />
          ),
        },
        {
          title: "LISTED MERCHANTS",
          dataIndex: ["org", "name"],
          width: "12%",
          fixed: "left",
          sorter: (a, b) => (a.category?.name || "").localeCompare(b.category?.name || ""),
          render: (text) => text || <span className="text-gray-400">Unset</span>,
        },
        {
          title: "STATUS",
          dataIndex: "status",
          width: "8%",
          fixed: "left",
          align: "center",
          sorter: (a, b) => (a.status || "On Sale").localeCompare(b.status || "On Sale"),
          render: (status = "On Sale") => (
            <span
              className={`badge rounded-full px-2 py-1 text-white ${
                status === "On Sale" ? "bg-[#67BE5F]" : "bg-[#B6B3A7]"
              }`}
            >
              {status}
            </span>
          ),
        },
        {
          title: "SERVICE NUMBER",
          dataIndex: "id",
          width: "12%",
          align: "center",
          sorter: (a, b) => a.id.localeCompare(b.id),
          render: (id) => id.substring(0, 5),
        },
        {
          title: "SERVICE NAME",
          dataIndex: "name",
          width: "12%",
          sorter: (a, b) => (a.name || "").localeCompare(b.name || ""),
          render: (text, record) => {
            const imageUrl = record.blobs?.[0]?.url || record.imageUrl || '/images/default-service.jpg';
            return (
              <div className="flex items-center">
                <img
                  src={imageUrl}
                  alt="服務縮圖"
                  className="w-6 h-6 object-cover rounded mr-2"
                />
                <span className="truncate">{text || '未設定'}</span>
              </div>
            );
          }
        },
        {
          title: "CATEGORY",
          dataIndex: "category",
          width: "8%",
          align: "center",
          sorter: (a, b) => (a.category?.name || "").localeCompare(b.category?.name || ""),
          render: (text) => text || <span className="text-gray-400">Unset</span>,
        },
        {
          title: "SERVICE AREA",
          dataIndex: "service_regions",
          width: "8%",
          align: "center",
          sorter: (a, b) => {
            const nameA = a.service_regions?.[0]?.name || "";
            const nameB = b.service_regions?.[0]?.name || "";
            return nameA.localeCompare(nameB);
          },
          render: (service_regions) => {
            if (!service_regions || service_regions.length === 0) {
              return <span className="text-gray-400">Unset</span>;
            }
            return service_regions[0].name;
          },
        },
        {
          title: "PRICE",
          dataIndex: "specs",
          width: "8%",
          align: "center",
          sorter: (a, b) => {
            const priceA = a.specs?.[0]?.price || 0;
            const priceB = b.specs?.[0]?.price || 0;
            return priceA - priceB;
          },
          render: (specs) => {
            if (!specs || specs.length === 0) {
              return <span className="text-gray-400">Unset</span>;
            }
            
            if (specs.length === 1) {
              return `$${specs[0].price}`;
            }
            
            const lowestPrice = Math.min(...specs.map(spec => spec.price));
            return `$${lowestPrice}`;
          },
        },
        {
          title: "ORDER NUMBER",
          dataIndex: "order",
          width: "10%",
          sorter: (a, b) => a.order - b.order,
          render: (order, record) => (
            <Button
              type="link"
              onClick={(e) => {
                e.stopPropagation();
                setShowOrderManagement(true);
                setSelectedService(record);
              }}
            >
              {order}
              <img
                src="/images/order.png"
                className="w-[70px]"
                alt="arrow"
              />
            </Button>
          ),
        },
        {
          title: "STOCK NUMBER",
          dataIndex: "specs",
          width: "10%",
          align: "center",
          sorter: (a, b) => {
            const stockA = a.specs?.[0]?.stock;
            const stockB = b.specs?.[0]?.stock;
            if (!stockA && !stockB) return 0;
            if (!stockA) return 1;
            if (!stockB) return -1;
            return parseInt(stockA) - parseInt(stockB);
          },
          render: (specs) => {
            const stock = specs?.[0]?.stock;
            if (!stock) {
              return <span className="text-gray-400">Unset</span>;
            }
            return stock === "0" ? "0" : stock;
          },
        },
        {
          title: "DISCOUNT",
          dataIndex: "discounts",
          width: "6%",
          render: (discounts) => discounts.length > 0 ? "open" : <span className="text-gray-400">OFF</span>,
        },
        {
          title: "TAG",
          dataIndex: "tags",
          width: "8%",
          render: (tags) => {
            if (!tags || tags.length === 0) {
              return <span className="text-gray-400">Unset</span>;
            }
            
            return (
              <div className="flex flex-wrap gap-1">
                {tags.map((tag) => (
                  <Tag 
                    key={tag} 
                    className="m-0 bg-[#F3E5D4] text-[#DFB782] border-[#DFB782]"
                  >
                    {tag}
                  </Tag>
                ))}
              </div>
            );
          },
        },
        {
          title: "LISTING DATE",
          dataIndex: "created_at",
          width: "8%",
          align: "center",
          render: (date) => date ? moment(date).format("YYYY-MM-DD") : <span className="text-gray-400">Unset</span>,
        },
    ],
    apis: {
        fetchCategories: async () => await callApi.get('/public/category/?product_type=service_product_ip'),      
    },
};

const pancoConfig = {
    columns: ({ setShowOrderManagement, setSelectedService, services, setServices }) => [
        {
          title: "推薦",
          width: "5%",
          fixed: "left",
          dataIndex: "recommendation",
          align: "center",
          sorter: (a, b) => {
            if (a.recommendation === null && b.recommendation === null) return 0;
            if (a.recommendation === null) return 1;
            if (b.recommendation === null) return -1;
            return a.recommendation - b.recommendation;
          },
          render: (value, record) => (
            <Input
              type="text"
              style={{ width: '60px' }}
              value={value || ''}
              onChange={(e) => {
                const inputValue = e.target.value;
      
                if (inputValue === '') {
                  const updatedServices = services.map(service => {
                    if (service.key === record.key) {
                      return { ...service, recommendation: null };
                    }
                    return service;
                  });
                  setServices(updatedServices);
                  return;
                }
      
                let newValue = parseInt(inputValue);
      
                if (isNaN(newValue) || newValue < 1 || newValue > 5) return;
      
                const isNumberUsed = services.some(
                  service => service.key !== record.key && service.recommendation === newValue
                );
      
                if (isNumberUsed) return;
      
                const updatedServices = services.map(service => {
                  if (service.key === record.key) {
                    return { ...service, recommendation: newValue };
                  }
                  return service;
                });
                setServices(updatedServices);
              }}
              className="[&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none [&]:MozAppearance-textfield"
            />
          ),
        },
        {
          title: "上架店家",
          dataIndex: ["org", "name"],
          width: "8%",
          fixed: "left",
          sorter: (a, b) => (a.category?.name || "").localeCompare(b.category?.name || ""),
          render: (text) => text || <span className="text-gray-400">Unset</span>,
        },
        {
          title: "前台狀態",
          dataIndex: "status",
          width: "8%",
          fixed: "left",
          align: "center",
          sorter: (a, b) => (a.status || "On Sale").localeCompare(b.status || "On Sale"),
          render: (status = "On Sale") => (
            <span
              className={`badge rounded-full px-2 py-1 text-white ${
                status === "On Sale" ? "bg-[#67BE5F]" : "bg-[#B6B3A7]"
              }`}
            >
              {status}
            </span>
          ),
        },
        {
          title: "服務序號",
          dataIndex: "id",
          width: "12%",
          align: "center",
          sorter: (a, b) => a.id.localeCompare(b.id),
          render: (id) => id.substring(0, 5),
        },
        {
          title: "服務名稱",
          dataIndex: "name",
          width: "12%",
          sorter: (a, b) => (a.name || "").localeCompare(b.name || ""),
          render: (text, record) => {
            const imageUrl = record.blobs?.[0]?.url || record.imageUrl || '/images/default-service.jpg';
            return (
              <div className="flex items-center">
                <img
                  src={imageUrl}
                  alt="服務縮圖"
                  className="w-6 h-6 object-cover rounded mr-2"
                />
                <span className="truncate">{text || '未設定'}</span>
              </div>
            );
          }
        },
        {
          title: "價格",
          dataIndex: "specs",
          width: "8%",
          align: "center",
          sorter: (a, b) => {
            const priceA = a.specs?.[0]?.price || 0;
            const priceB = b.specs?.[0]?.price || 0;
            return priceA - priceB;
          },
          render: (specs) => {
            if (!specs || specs.length === 0) {
              return <span className="text-gray-400">Unset</span>;
            }
            
            if (specs.length === 1) {
              return `$${specs[0].price}`;
            }
            
            const lowestPrice = Math.min(...specs.map(spec => spec.price));
            return `$${lowestPrice}`;
          },
        },
        {
          title: "訂單數",
          dataIndex: "order",
          width: "10%",
          sorter: (a, b) => a.order - b.order,
          render: (order, record) => (
            <Button
              type="link"
              onClick={(e) => {
                e.stopPropagation();
                setShowOrderManagement(true);
                setSelectedService(record);
              }}
            >
              {order}
              <img
                src="/images/order.png"
                className="w-[70px]"
                alt="arrow"
              />
            </Button>
          ),
        },
        {
          title: "庫存數",
          dataIndex: "specs",
          width: "10%",
          align: "center",
          sorter: (a, b) => {
            const stockA = a.specs?.[0]?.stock;
            const stockB = b.specs?.[0]?.stock;
            if (!stockA && !stockB) return 0;
            if (!stockA) return 1;
            if (!stockB) return -1;
            return parseInt(stockA) - parseInt(stockB);
          },
          render: (specs) => {
            const stock = specs?.[0]?.stock;
            if (!stock) {
              return <span className="text-gray-400">Unset</span>;
            }
            return stock === "0" ? "0" : stock;
          },
        },
        {
          title: "優惠",
          dataIndex: "memberDiscount",
          width: "6%",
          render: (memberDiscount, record) => {
            const discounts = [];
            if (memberDiscount && memberDiscount !== "無折扣") {
              discounts.push(`會員${memberDiscount}`);
            }
            if (record.earlyBirdDiscount && record.earlyBirdDiscount !== "無折扣") {
              discounts.push(`晚鳥${record.earlyBirdDiscount}`);
            }
            return discounts.length > 0 ? "open" : <span className="text-gray-400">OFF</span>;
          },
        },
        {
          title: "標籤",
          dataIndex: "tags",
          width: "8%",
          render: (tags) => {
            if (!tags || tags.length === 0) {
              return <span className="text-gray-400">Unset</span>;
            }
            
            return (
              <div className="flex flex-wrap gap-1">
                {tags.map((tag) => (
                  <Tag 
                    key={tag} 
                    className="m-0 bg-[#F3E5D4] text-[#DFB782] border-[#DFB782]"
                  >
                    {tag}
                  </Tag>
                ))}
              </div>
            );
          },
        },
        {
          title: "上架日期",
          dataIndex: "created_at",
          width: "8%",
          align: "center",
          render: (date) => date ? moment(date).format("YYYY-MM-DD") : <span className="text-gray-400">Unset</span>,
        },
      ],
    formFields: [
        { name: 'name', label: '服務名稱', type: 'text', required: true },
        { name: 'price', label: '價格', type: 'number', required: true },
        { name: 'description', label: '描述', type: 'textarea', required: false },
    ],
    onPublish: (formData, { saveServiceData }) => {
        // 保存表單數據到 context
        saveServiceData(formData);
    },
    apis: {
        fetchCategories: async () => await callApi.get('/public/category/?product_type=service_product_ii'),      
    },
};

// 根據環境變數選擇配置
const isPanco = process.env.REACT_APP_PANCO_E_COMMERCE === 'true';
const isStag5 = process.env.REACT_APP_STAG5_E_COMMERCE === 'true';


export const serviceConfig = isPanco ? pancoConfig : isStag5 ? stag5Config : null;
