import { Input, Select, DatePicker, InputNumber, Upload, Radio, Button, Table, Tag, message, Switch, Form, Row, Col, Divider, Space, Checkbox, TimePicker } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import { fetchCategoriesAPI, fetchOrganizationsAPI, createOrganizationAPI, createSpecAPI, createDiscountAPI, createProviderAPI, verifyOrganizationAPI } from '@/app/ams/services/api';
import { useAmsContext } from '../../context/AmsContext';
import { fetchIdentityListAPI } from '../../services/api';
// TagSelector
const TagSelector = ({ value = [], onChange }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleTagClose = (removedTag) => {
        const newTags = value.filter(tag => tag !== removedTag);
        onChange(newTags);
    };

    return (
        <div className="flex flex-wrap gap-2 border border-gray-300 rounded-lg p-2 min-w-[400px] w-fit">
            {value.map((tag) => (
                <Tag
                    key={tag}
                    closable
                    onClose={() => handleTagClose(tag)}
                    className="bg-green-50 text-green-600 border-green-200"
                >
                    {tag}
                </Tag>
            ))}
            {value.length < 5 && (
                <Tag
                    className="border-dashed border-gray-300 cursor-pointer hover:border-green-500"
                    onClick={() => setIsModalVisible(true)}
                >
                    <PlusOutlined />
                </Tag>
            )}
        </div>
    );
};

// useAmsContext 
const BeauticianSelect = ({ value, onChange }) => {
    const { identityList } = useAmsContext();
    
    // 將 identityList 轉換為包含 providers 的選項
    const options = identityList.reduce((acc, identity) => {
        if (identity.providers && identity.providers.length > 0) {
            // 為每個 provider 創建一個選項
            const providerOptions = identity.providers.map(provider => ({
                label: `${identity.name}-${provider.last_name || provider.first_name || provider.nickname || provider.email}`,
                value: JSON.stringify({
                    provider_id: provider.id,
                    identity_id: identity.id
                })
            }));
            return [...acc, ...providerOptions];
        }
        return acc;
    }, []);

    // 處理值的轉換
    const handleChange = (selectedValues) => {
        const formattedValues = selectedValues.map(value => JSON.parse(value));
        onChange(formattedValues);
    };

    // 將 value 轉換回 Select 組件需要的格式
    const formattedValue = value ? value.map(item => 
        JSON.stringify({
            provider_id: item.provider_id,
            identity_id: item.identity_id
        })
    ) : [];

    return (
        <Select
            mode="multiple"
            value={formattedValue}
            onChange={handleChange}
            options={options}
            optionFilterProp="label"
            showSearch
            style={{ width: '100%' }}
            filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
        />
    );
};

// fetch categories
const CategorySelect = ({ value, onChange }) => {
    const { categories, setCategories } = useAmsContext();
    const stag5 = process.env.REACT_APP_STAG5_E_COMMERCE === 'true';
    const panco = process.env.REACT_APP_PANCO_E_COMMERCE === 'true';
    
    useEffect(() => {
        let cats = [];
        const fetchCategories = async () => {
            if( stag5 === true ) {
                const type = 'service_product_ip';
                cats = await fetchCategoriesAPI(type);
            } else if ( panco === true ) {
                const type = 'service_product_ii';
                cats = await fetchCategoriesAPI(type);
            }
            setCategories(cats);
        };
        fetchCategories();
    }, []);
    return (
        <Select
            value={value?.name} // 只顯示名稱
            onChange={(_, option) => {
                onChange({
                    id: option.data.id,
                    name: option.data.name,
                    product_type: option.data.product_type
                });
            }}
            options={categories.map(category => ({
                value: category.id,
                label: category.name,
                data: category // 保存完整的類別數據
            }))}
            optionFilterProp="label"
            showSearch
            style={{ width: '100%' }}
        />
    );
};

// fetch organizations
const OrganizationSelect = (props) => {
    const { value, onChange = () => {}, ...restProps } = props;
    const form = Form.useFormInstance();
    const { 
        organizations, 
        setOrganizations, 
        setIdentityList 
    } = useAmsContext();
    
    useEffect(() => {
        const initOrganizations = async () => {
            try {
                const orgs = await fetchOrganizationsAPI();
                setOrganizations(orgs.data);
            } catch (error) {
                console.error('獲取店家列表失敗:', error);
                message.error({
                    content: '獲取店家列表失敗',
                    className: 'custom-message'
                });
            }
        };
        initOrganizations();
    }, [setOrganizations]);

    const handleChange = async (selectedValue) => {
        try {
            // 直接使用 form.setFieldValue 來更新表單值
            form.setFieldValue(['basic', 'organization'], selectedValue);
            
            if (selectedValue) {
                const res = await fetchIdentityListAPI(selectedValue);
                if (res) {
                    setIdentityList(res);
                }
            } else {
                setIdentityList([]);
            }
        } catch (error) {
            console.error('獲取美容師失敗:', error);
            message.error({
                content: '獲取美容師列表失敗',
                className: 'custom-message'
            });
        }
    };

    return (
        <Select
            {...restProps}
            value={value}
            onChange={handleChange}
            options={(Array.isArray(organizations) ? organizations : []).map(organization => ({
                label: organization.name,
                value: organization.id
            }))}
            optionFilterProp="label"
            showSearch
            style={{ width: '100%' }}
            filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
        />
    );
};

// SpecTableComponent
const SpecTableComponent = () => {
    const [columns, setColumns] = useState([]);
    const [dataSource, setDataSource] = useState([]);

    const form = Form.useFormInstance();
    const specSettings = Form.useWatch('specSettings', form);

    useEffect(() => {
        if (specSettings && specSettings.length > 0) {
            // 動態生成規格列
            const dynamicColumns = [];
            
            // 第一個規格列
            if (specSettings[0]) {
                dynamicColumns.push({
                    title: specSettings[0].specName || '規格1',
                    dataIndex: 'spec0',
                    key: 'spec0',
                });
            }

            // 第二個規格列（如果存在）
            if (specSettings[1]) {
                dynamicColumns.push({
                    title: specSettings[1].specName || '規格2',
                    dataIndex: 'spec1',
                    key: 'spec1',
                    render: (text, record) => {
                        // 如果有多個選項，以上下格方顯示
                        return (
                            <div className="flex flex-col">
                                {Array.isArray(record.spec1) ? record.spec1.map((option, index) => (
                                    <div key={index} className={`p-2 ${index !== record.spec1.length - 1 ? 'border-b' : ''}`}>
                                        {option}
                                    </div>
                                )) : text}
                            </div>
                        );
                    }
                });
            }

            // 固定的列（價格、折扣等）
            const fixedColumns = [
                {
                    title: '價格',
                    dataIndex: 'price',
                    key: 'price',
                    render: (value, record, rowIndex) => (
                        <div className="flex flex-col">
                            {Array.isArray(record.spec1) ? record.spec1.map((_, optionIndex) => (
                                <Form.Item
                                    key={optionIndex}
                                    name={['specData', rowIndex, 'options', optionIndex, 'price']}
                                    rules={[{ required: true, message: '請輸入價格' }]}
                                    className={`mb-0 ${optionIndex !== record.spec1.length - 1 ? 'border-b pb-2' : ''} ${optionIndex !== 0 ? 'pt-2' : ''}`}
                                >
                                    <InputNumber
                                        min={0}
                                        formatter={(value) => `$ ${value}`}
                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            )) : (
                                <Form.Item
                                    name={['specData', rowIndex, 'price']}
                                    rules={[{ required: true, message: '請輸入價格' }]}
                                >
                                    <InputNumber
                                        min={0}
                                        formatter={(value) => `$ ${value}`}
                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            )}
                        </div>
                    ),
                },
                {
                    title: '折扣',
                    dataIndex: 'discount',
                    key: 'discount',
                    render: (value, record, rowIndex) => (
                        <div className="flex flex-col">
                            {Array.isArray(record.spec1) ? record.spec1.map((_, optionIndex) => (
                                <Form.Item
                                    key={optionIndex}
                                    name={['specData', rowIndex, 'options', optionIndex, 'discount']}
                                    valuePropName="checked"
                                    className={`mb-0 ${optionIndex !== record.spec1.length - 1 ? 'border-b pb-2' : ''} ${optionIndex !== 0 ? 'pt-2' : ''}`}
                                >
                                    <Switch
                                        checkedChildren="開"
                                        unCheckedChildren="關"
                                    />
                                </Form.Item>
                            )) : (
                                <Form.Item
                                    name={['specData', rowIndex, 'discount']}
                                    valuePropName="checked"
                                >
                                    <Switch
                                        checkedChildren="開"
                                        unCheckedChildren="關"
                                    />
                                </Form.Item>
                            )}
                        </div>
                    ),
                },
                // 其他列（折扣價、晚鳥優惠等）使用相同的模式
                {
                    title: '折扣價',
                    dataIndex: 'discountPrice',
                    key: 'discountPrice',
                    render: (value, record, rowIndex) => (
                        <div className="flex flex-col">
                            {Array.isArray(record.spec1) ? record.spec1.map((_, optionIndex) => {
                                const discount = form.getFieldValue(['specData', rowIndex, 'options', optionIndex, 'discount']);
                                return (
                                    <Form.Item
                                        key={optionIndex}
                                        name={['specData', rowIndex, 'options', optionIndex, 'discountPrice']}
                                        rules={[{ required: discount, message: '請輸入折扣價' }]}
                                        className={`mb-0 ${optionIndex !== record.spec1.length - 1 ? 'border-b pb-2' : ''} ${optionIndex !== 0 ? 'pt-2' : ''}`}
                                    >
                                        <InputNumber
                                            min={0}
                                            formatter={(value) => `$ ${value}`}
                                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                            style={{ width: '100%' }}
                                            disabled={!discount}
                                        />
                                    </Form.Item>
                                );
                            }) : (
                                <Form.Item
                                    name={['specData', rowIndex, 'discountPrice']}
                                    rules={[{ required: form.getFieldValue(['specData', rowIndex, 'discount']), message: '請輸入折扣價' }]}
                                >
                                    <InputNumber
                                        min={0}
                                        formatter={(value) => `$ ${value}`}
                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                        style={{ width: '100%' }}
                                        disabled={!form.getFieldValue(['specData', rowIndex, 'discount'])}
                                    />
                                </Form.Item>
                            )}
                        </div>
                    ),
                },
                {
                    title: '晚鳥優惠',
                    dataIndex: 'lateDiscount',
                    key: 'lateDiscount',
                    render: (value, record, rowIndex) => (
                        <div className="flex flex-col">
                            {Array.isArray(record.spec1) ? record.spec1.map((_, optionIndex) => (
                                <Form.Item
                                    key={optionIndex}
                                    name={['specData', rowIndex, 'options', optionIndex, 'lateDiscount']}
                                    valuePropName="checked"
                                    className={`mb-0 ${optionIndex !== record.spec1.length - 1 ? 'border-b pb-2' : ''} ${optionIndex !== 0 ? 'pt-2' : ''}`}
                                >
                                    <Switch
                                        checkedChildren="開"
                                        unCheckedChildren="關"
                                    />
                                </Form.Item>
                            )) : (
                                <Form.Item
                                    name={['specData', rowIndex, 'lateDiscount']}
                                    valuePropName="checked"
                                >
                                    <Switch
                                        checkedChildren="開"
                                        unCheckedChildren="關"
                                    />
                                </Form.Item>
                            )}
                        </div>
                    ),
                },
                {
                    title: '晚鳥優惠價',
                    dataIndex: 'lateDiscountPrice',
                    key: 'lateDiscountPrice',
                    render: (value, record, rowIndex) => (
                        <div className="flex flex-col">
                            {Array.isArray(record.spec1) ? record.spec1.map((_, optionIndex) => {
                                const lateDiscount = form.getFieldValue(['specData', rowIndex, 'options', optionIndex, 'lateDiscount']);
                                return (
                                    <Form.Item
                                        key={optionIndex}
                                        name={['specData', rowIndex, 'options', optionIndex, 'lateDiscountPrice']}
                                        rules={[{ required: lateDiscount, message: '請輸入晚鳥優惠價' }]}
                                        className={`mb-0 ${optionIndex !== record.spec1.length - 1 ? 'border-b pb-2' : ''} ${optionIndex !== 0 ? 'pt-2' : ''}`}
                                    >
                                        <InputNumber
                                            min={0}
                                            formatter={(value) => `$ ${value}`}
                                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                            style={{ width: '100%' }}
                                            disabled={!lateDiscount}
                                        />
                                    </Form.Item>
                                );
                            }) : (
                                <Form.Item
                                    name={['specData', rowIndex, 'lateDiscountPrice']}
                                    rules={[{ required: form.getFieldValue(['specData', rowIndex, 'lateDiscount']), message: '請輸入晚鳥優惠價' }]}
                                >
                                    <InputNumber
                                        min={0}
                                        formatter={(value) => `$ ${value}`}
                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                        style={{ width: '100%' }}
                                        disabled={!form.getFieldValue(['specData', rowIndex, 'lateDiscount'])}
                                    />
                                </Form.Item>
                            )}
                        </div>
                    ),
                },
                {
                    title: '庫存',
                    dataIndex: 'stock',
                    key: 'stock',
                    render: (value, record, rowIndex) => (
                        <div className="flex flex-col">
                            {Array.isArray(record.spec1) ? record.spec1.map((_, optionIndex) => (
                                <Form.Item
                                    key={optionIndex}
                                    className={`mb-0 ${optionIndex !== record.spec1.length - 1 ? 'border-b pb-2' : ''} ${optionIndex !== 0 ? 'pt-2' : ''}`}
                                >
                                    <Form.Item
                                        name={['specData', rowIndex, 'options', optionIndex, 'stock']}
                                        initialValue="unlimited"
                                        noStyle
                                    >
                                        <Radio.Group>
                                            <Radio value="unlimited">無限</Radio>
                                            <Radio value="limited">
                                                <Form.Item
                                                    name={['specData', rowIndex, 'options', optionIndex, 'stockAmount']}
                                                    noStyle
                                                    rules={[{
                                                        required: form.getFieldValue(['specData', rowIndex, 'options', optionIndex, 'stock']) === 'limited',
                                                        message: '請輸入庫存數量'
                                                    }]}
                                                >
                                                    <InputNumber
                                                        min={0}
                                                        disabled={form.getFieldValue(['specData', rowIndex, 'options', optionIndex, 'stock']) !== 'limited'}
                                                        style={{ width: '80px', marginLeft: 8 }}
                                                    />
                                                </Form.Item>
                                            </Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Form.Item>
                            )) : (
                                <Form.Item
                                    name={['specData', rowIndex, 'stock']}
                                    initialValue="unlimited"
                                >
                                    <Radio.Group>
                                        <Radio value="unlimited">無</Radio>
                                        <Radio value="limited">
                                            <Form.Item
                                                name={['specData', rowIndex, 'stockAmount']}
                                                noStyle
                                                rules={[{
                                                    required: form.getFieldValue(['specData', rowIndex, 'stock']) === 'limited',
                                                    message: '請輸入庫存數量'
                                                }]}
                                            >
                                                <InputNumber
                                                    min={0}
                                                    disabled={form.getFieldValue(['specData', rowIndex, 'stock']) !== 'limited'}
                                                    style={{ width: '80px', marginLeft: 8 }}
                                                />
                                            </Form.Item>
                                        </Radio>
                                    </Radio.Group>
                                </Form.Item>
                            )}
                        </div>
                    ),
                },
            ];

            // 修改生成規格組合的邏輯
            const combinations = generateCombinations(specSettings);
            const data = combinations.map((combination, index) => ({
                key: index,
                ...combination,
            }));

            setColumns([...dynamicColumns, ...fixedColumns]);
            setDataSource(data);
        }
    }, [specSettings, form]);

    // 修改生成規格組合的方法
    const generateCombinations = (specs) => {
        if (!specs || specs.length === 0) return [];

        const spec1Options = specs[0]?.options.filter(opt => opt.trim() !== '') || [];
        const spec2Options = specs[1]?.options.filter(opt => opt.trim() !== '') || [];

        return spec1Options.map(spec1Option => ({
            spec0: spec1Option,
            spec1: spec2Options.length > 0 ? spec2Options : undefined,
        }));
    };

    return (
        <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            bordered
        />
    );
};

// DiscountInput
const DiscountInput = ({ name, onChange }) => {
    const form = Form.useFormInstance();
    
    return (
        <Space.Compact>
            <InputNumber
                style={{ width: '120px' }}
                min={1}
                max={9}
                placeholder="請輸入數字"
                onChange={(value) => {
                    const percentOff = value ? (10 - value) * 10 : 0;
                    form.setFieldValue([...name, 'percentOff'], percentOff);
                    onChange?.(value);
                }}
            />
            <span 
                style={{ 
                    display: 'inline-block', 
                    lineHeight: '32px', 
                    padding: '0 8px', 
                    color: '#595959' 
                }}
            >
                折 =
            </span>
            <Form.Item
                name={[...name, 'percentOff']}
                noStyle
            >
                <InputNumber
                    style={{ width: '120px' }}
                    disabled
                    formatter={value => `${value}`}
                />
            </Form.Item>
            <span 
                style={{ 
                    display: 'inline-block', 
                    lineHeight: '32px', 
                    padding: '0 8px',
                    color: '#595959'
                }}
            >
                % off
            </span>
        </Space.Compact>
    );
};

const pancoConfig = {
    tabs: [
        { key: 'basic', label: '基本資訊' },
    ],
    organization: {
        title: '店家管理',
        add: '新增店家',
        draft: '儲存至草稿夾',
        publish: '儲存並發布'
    },
    formItems: {
        basic: [
            {
                name: ['basic', 'organization'],
                label: '選擇店家',
                type: 'select',
                required: true,
                component: OrganizationSelect,
                col: 12,
                labelCol: { span: 24 },
                wrapperCol: { span: 8 },
                rules: [{ required: true, message: '請選擇店家' }],
                style: {
                    display: 'block',
                    width: '100%'
                }
            },
            {
                name: 'category',
                label: '選擇類別',
                type: 'select',
                required: true,
                component: CategorySelect,
                col: 12,
                labelCol: { span: 24 },
                wrapperCol: { span: 8 },
                rules: [{ required: true, message: '請選擇類別' }],
                style: {
                    display: 'block',
                    width: '100%'
                },
                options: {
                    placeholder: '請選擇類別',
                    options: ({ categories }) =>
                        categories?.map((category) => ({
                            label: category.name,
                            value: category.id
                        })) || []
                }
            },
            {
                name: 'name',
                label: '服務名稱',
                type: 'text',
                required: true,
                component: Input,
                col: 12,
                options: {
                    placeholder: '請輸入服務名稱'
                },
                rules: [
                    { required: true, message: '請輸入服務名稱' },
                    { max: 128, message: '最多128字元' }
                ]
            },
            {
                name: 'serviceCode',
                label: '服務序號',
                type: 'text',
                required: true,
                component: Input,
                col: 12,
                placeholder: '系統自動填入',
                options: {
                    disabled: true,
                    placeholder: '系統自動填入',
                    style: {
                        background: '#d9d9d9'
                    }
                }
            },
            {
                name: 'serviceImage',
                label: '服務主要圖片',
                type: 'upload',
                required: true,
                component: Upload,
                col: 12,
                valuePropName: 'fileList',
                getValueFromEvent: e => e?.fileList,
                options: {
                    listType: "picture-card",
                    maxCount: 1,
                    children: (
                        <div>
                            <PlusOutlined />
                            <div style={{ marginTop: 8 }}>上傳</div>
                        </div>
                    )
                }
            },
            {
                name: 'serviceOtherImage',
                label: '其他照片',
                type: 'upload',
                required: false,
                component: Upload,
                col: 12,
                valuePropName: 'fileList',
                getValueFromEvent: e => e?.fileList,
                options: {
                    listType: "picture-card",
                    maxCount: 5,
                    children: (
                        <div>
                            <PlusOutlined />
                            <div style={{ marginTop: 8 }}>上傳</div>
                        </div>
                    )
                }
            },
            {
                name: 'serviceDescription',
                label: '服務描述',
                type: 'textarea',
                required: true,
                component: Input.TextArea,
                col: 24,
                options: {
                    autoSize: { minRows: 4, maxRows: 4 },
                    showCount: true,
                    maxLength: 500,
                    placeholder: '請輸入服務介紹',
                    style: {
                        resize: 'none',
                        height: '120px'
                    }
                }
            },
            {
                name: 'serviceNotice',
                label: '注意事項說明',
                type: 'textarea',
                required: true,
                component: Input.TextArea,
                col: 24,
                options: {
                    autoSize: { minRows: 4, maxRows: 4 },
                    showCount: true,
                    maxLength: 500,
                    placeholder: '請輸入注意事項、使用規則',
                    style: {
                        resize: 'none',
                        height: '120px'
                    }
                }
            },
            {
                name: 'reservation',
                label: '預約',
                type: 'select',
                col: 6,
                required: true,
                component: Select,
                labelCol: { span: 24 },
                style: {
                    display: 'block',
                    width: '100%'
                },
                options: {
                    placeholder: '請選擇預約方式',
                    options: [
                        { label: '不需預約', value: 'no_reservation' },
                        { label: '須預約日期', value: 'date_only' },
                        { label: '須預約日期及時段', value: 'date_time' }
                    ]
                },
                initialValue: 'no_reservation'
            },
            {
                name: 'reservationPeriod',
                col: 6,
                type: 'custom',
                component: () => (
                    <Form.Item
                        name="reservationPeriod"
                        className="custom-label-flex"
                        label={
                            <div style={{ 
                                display: 'flex',
                                justifyContent: 'space-between', 
                                width: '100%'
                            }}>
                                <span>可預約區間</span>
                                <Form.Item
                                    name={['reservationPeriod', 'noLimit']}
                                    valuePropName="checked"
                                    noStyle
                                >
                                    <Checkbox>不限</Checkbox>
                                </Form.Item>
                            </div>
                        }
                        labelCol={{ 
                            style: { 
                                width: '100%'
                            }
                        }}
                    >
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => {
                                return prevValues?.reservationPeriod?.noLimit !== currentValues?.reservationPeriod?.noLimit;
                            }}
                        >
                            {({ getFieldValue }) => (
                                <DatePicker.RangePicker 
                                    style={{ width: '100%' }}
                                    disabled={getFieldValue(['reservationPeriod', 'noLimit'])}
                                />
                            )}
                        </Form.Item>
                    </Form.Item>
                )
            },
            {
                name: 'servicePeriod',
                col: 24,
                type: 'custom',
                required: true,
                component: () => (
                    <Form.Item
                        name="servicePeriod"
                        className="custom-label-flex"
                        label={
                            <div style={{ 
                                display: 'flex',
                                justifyContent: 'space-between', 
                                width: '100%'
                            }}>
                                <span>使用期間</span>
                                <Form.Item
                                    name={['servicePeriod', 'noLimit']}
                                    valuePropName="checked"
                                    noStyle
                                >
                                    <Checkbox>無期限</Checkbox>
                                </Form.Item>
                            </div>
                        }
                        labelCol={{ 
                            style: { 
                                width: '100%'
                            }
                        }}
                        wrapperCol={{
                            span: 6
                        }}
                        style={{
                            marginTop: '16px'
                        }}
                    >
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => {
                                return prevValues?.servicePeriod?.noLimit !== currentValues?.servicePeriod?.noLimit;
                            }}
                        >
                            {({ getFieldValue }) => (
                                <DatePicker.RangePicker 
                                    style={{ width: '100%' }}
                                    disabled={getFieldValue(['servicePeriod', 'noLimit'])}
                                />
                            )}
                        </Form.Item>
                    </Form.Item>
                )
            },
            {
                name: 'serviceTags',
                label: '標籤',
                type: 'custom',
                required: true,
                component: TagSelector,
                col: 24,
                labelCol: { span: 24 }
            },
            {
                name: 'serviceNote',
                label: '服務備註（小標呈現）',
                type: 'text',
                required: true,
                component: Input,
                col: 24,
                options: {
                    maxLength: 128,
                    placeholder: '請輸入備註（25字內）'
                }
            },
            {
                name: 'basicDivider',
                type: 'custom',
                component: () => <Divider className="my-1 border-t-2 border-solid border-gray-300" />,
                col: 24
            }
        ]
    }
};

const stag5Config = {
    tabs: [
        { key: 'basic', label: 'Basic Information' },
    ],
    organization: {
        title: 'Organization Management',
        add: 'Add Organization',
        draft: 'Save to Draft',
        publish: 'Save and Publish'
    },
    formItems: {
        basic: [
            {
                name: 'name',
                label: 'Store Name',
                type: 'text',
                required: true,
                component: Input,
                col: 12,
                options: {
                    placeholder: 'Enter store name'
                },
                rules: [
                    { required: true, message: 'Please enter store name' },
                    { max: 128, message: 'Maximum 128 characters' }
                ]
            },
            {
                name: 'serviceCode',
                label: 'Store Serial Number',
                type: 'text',
                required: true,
                component: Input,
                col: 12,
                placeholder: 'Auto-generated',
                options: {
                    disabled: true,
                    placeholder: 'Auto-generated',
                    style: {
                        background: '#d9d9d9'
                    }
                }
            },
            {
                name: 'serviceImage',
                label: 'Main Store Image',
                type: 'upload',
                required: true,
                component: Upload,
                col: 12,
                valuePropName: 'fileList',
                getValueFromEvent: e => e?.fileList,
                options: {
                    listType: "picture-card",
                    maxCount: 1,
                    children: (
                        <div>
                            <PlusOutlined />
                            <div style={{ marginTop: 8 }}>Upload</div>
                        </div>
                    )
                }
            },
            {
                name: 'serviceOtherImage',
                label: 'Other Photos',
                type: 'upload',
                required: false,
                component: Upload,
                col: 12,
                valuePropName: 'fileList',
                getValueFromEvent: e => e?.fileList,
                options: {
                    listType: "picture-card",
                    maxCount: 5,
                    children: (
                        <div>
                            <PlusOutlined />
                            <div style={{ marginTop: 8 }}>Upload</div>
                        </div>
                    )
                }
            },
            {
                name: 'storePhone',
                label: 'Store Phone Number',
                type: 'text',
                required: true,
                component: Input,
                col: 12,
            },
            {
                name: 'email',
                label: 'Store Email',
                type: 'text',
                required: true,
                component: Input,
                rules: [
                    { required: true, message: 'Please enter store email' },
                ],
                col: 12,
            },
            {
                name: 'personInCharge',
                label: 'Person in Charge',
                type: 'text',
                required: true,
                component: Input,
                col: 12,
            },
            {
                name: 'phone',
                label: 'Phone Number',
                type: 'text',
                required: true,
                rules: [
                    { required: true, message: 'Please enter store phone number' },
                ],
                component: Input,
                col: 12,
            },
            {
                name: 'revenueShareRatio',
                label: 'Revenue Share Ratio (in %)',
                type: 'text',
                required: true,
                component: Input,
                col: 12,
            },
            {
                name: 'rewardPoints',
                label: 'Reward Points (in points)',
                type: 'text',
                required: true,
                component: Input,
                col: 12,
            },
            {
                name: 'serviceDescription',
                label: 'Service Description',
                type: 'textarea',
                required: true,
                component: Input.TextArea,
                col: 24,
                options: {
                    autoSize: { minRows: 4, maxRows: 4 },
                    showCount: true,
                    maxLength: 500,
                    placeholder: 'Enter service description',
                    style: {
                        resize: 'none',
                        height: '120px'
                    }
                }
            },
            {
                name: 'serviceNotice',
                label: 'Important Notes',
                type: 'textarea',
                required: true,
                component: Input.TextArea,
                col: 24,
                options: {
                    autoSize: { minRows: 4, maxRows: 4 },
                    showCount: true,
                    maxLength: 500,
                    placeholder: 'Enter important notes and usage rules',
                    style: {
                        resize: 'none',
                        height: '120px'
                    }
                }
            },
            {
                name: 'reservation',
                label: 'Reservation',
                type: 'select',
                col: 6,
                required: true,
                component: Select,
                labelCol: { span: 24 },
                style: {
                    display: 'block',
                    width: '100%'
                },
                options: {
                    placeholder: 'Select reservation type',
                    options: [
                        { label: 'No Reservation Required', value: 'no_reservation' },
                        { label: 'Date Only', value: 'date_only' },
                        { label: 'Date and Time', value: 'date_time' }
                    ]
                },
                initialValue: 'no_reservation'
            },
            {
                name: 'reservationPeriod',
                col: 6,
                type: 'custom',
                component: () => (
                    <Form.Item
                        name="reservationPeriod"
                        className="custom-label-flex"
                        label={
                            <div style={{ 
                                display: 'flex',
                                justifyContent: 'space-between', 
                                width: '100%'
                            }}>
                                <span>Reservation Period</span>
                                <Form.Item
                                    name={['reservationPeriod', 'noLimit']}
                                    valuePropName="checked"
                                    noStyle
                                >
                                    <Checkbox>No Limit</Checkbox>
                                </Form.Item>
                            </div>
                        }
                        labelCol={{ 
                            style: { 
                                width: '100%'
                            }
                        }}
                    >
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => {
                                return prevValues?.reservationPeriod?.noLimit !== currentValues?.reservationPeriod?.noLimit;
                            }}
                        >
                            {({ getFieldValue }) => (
                                <DatePicker.RangePicker 
                                    style={{ width: '100%' }}
                                    disabled={getFieldValue(['reservationPeriod', 'noLimit'])}
                                />
                            )}
                        </Form.Item>
                    </Form.Item>
                )
            },
            {
                name: 'servicePeriod',
                col: 24,
                type: 'custom',
                required: true,
                component: () => (
                    <Form.Item
                        name="servicePeriod"
                        className="custom-label-flex"
                        label={
                            <div style={{ 
                                display: 'flex',
                                justifyContent: 'space-between', 
                                width: '100%'
                            }}>
                                <span>Service Period</span>
                                <Form.Item
                                    name={['servicePeriod', 'noLimit']}
                                    valuePropName="checked"
                                    noStyle
                                >
                                    <Checkbox>No Limit</Checkbox>
                                </Form.Item>
                            </div>
                        }
                        labelCol={{ 
                            style: { 
                                width: '100%'
                            }
                        }}
                        wrapperCol={{
                            span: 6
                        }}
                        style={{
                            marginTop: '16px'
                        }}
                    >
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => {
                                return prevValues?.servicePeriod?.noLimit !== currentValues?.servicePeriod?.noLimit;
                            }}
                        >
                            {({ getFieldValue }) => (
                                <DatePicker.RangePicker 
                                    style={{ width: '100%' }}
                                    disabled={getFieldValue(['servicePeriod', 'noLimit'])}
                                />
                            )}
                        </Form.Item>
                    </Form.Item>
                )
            },
            {
                name: 'serviceTags',
                label: 'Tags',
                type: 'custom',
                required: true,
                component: TagSelector,
                col: 24,
                labelCol: { span: 24 }
            },
            {
                name: 'serviceNote',
                label: 'Service Note (Subtitle)',
                type: 'text',
                required: true,
                component: Input,
                col: 24,
                options: {
                    maxLength: 128,
                    placeholder: 'Enter note (max 25 characters)'
                }
            },
            {
                name: 'basicDivider',
                type: 'custom',
                component: () => <Divider className="my-1 border-t-2 border-solid border-gray-300" />,
                col: 24
            }
        ]
    }
};

// form scroll active tab
const useFormScroll = (formRef) => {
    const [activeTab, setActiveTab] = useState('basic');

    useEffect(() => {
        const handleScroll = () => {
            if (!formRef.current) return;

            const container = formRef.current.closest('.overflow-auto');
            if (!container) return;

            const containerTop = container.getBoundingClientRect().top;
            const offset = 200; // 整這個值來改變切換的觸發位置

            const tabSections = pancoConfig.tabs.map(tab => {
                const element = document.getElementById(tab.key);
                if (!element) return { key: tab.key, top: Infinity };
                const rect = element.getBoundingClientRect();
                // 計算相對於容器的位置
                const relativeTop = rect.top - containerTop;
                return {
                    key: tab.key,
                    top: relativeTop
                };
            }).filter(section => section.top !== Infinity);

            // 找到最接近但還未超過觸發位置的section
            const currentSection = tabSections.reduce((prev, curr) => {
                if (curr.top <= offset && curr.top > prev.top) {
                    return curr;
                }
                return prev;
            }, { key: tabSections[0].key, top: -Infinity });

            if (currentSection && currentSection.key !== activeTab) {
                setActiveTab(currentSection.key);
            }
        };

        const container = formRef.current?.closest('.overflow-auto');
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => container.removeEventListener('scroll', handleScroll);
        }
    }, [formRef, activeTab]);

    return activeTab;
};

// 新增表單邏輯處理
const formLogic = {
  handleSubmit: async (values) => {
    try {
        const discounts = [];
        const specs = [];


        console.log('表單原始資料', values);
        
        const formattedData = {
            name: values.name || '',
            phone: values.phone || '',
            email: values.email || '',
            // address: values.serviceNotice || '',
            // region_id: values.category?.id || '',
            // service_region_ids: values.beauticians?.map(item => item.identity_id) || [],
        };

        // 處理規格金額資料

        // if (values.priceType === 'spec' && values.specData) {
        //     // 取得規格1和規格2的所有選項（過濾空值）
        //     const spec1Options = values.specSettings[0]?.options.filter(opt => opt.trim() !== '') || [];
        //     const spec2Options = values.specSettings[1]?.options.filter(opt => opt.trim() !== '') || [];
            
        //     values.specData.forEach((row, rowIndex) => {
        //         const spec1Name = values.specSettings[0]?.specName || '';
        //         const spec2Name = values.specSettings[1]?.specName || '';
                
        //         if (row.options) {
        //             // 多規格情況
        //             row.options.forEach((option, optionIndex) => {
        //                 const information = {
        //                     tier_variations: [
        //                         {
        //                             name: spec1Name,
        //                             value: spec1Options[rowIndex]
        //                         }
        //                     ]
        //                 };

        //                 // 如果有規格2，則添加到 tier_variations
        //                 if (spec2Name && spec2Options[optionIndex]) {
        //                     information.tier_variations.push({
        //                         name: spec2Name,
        //                         value: spec2Options[optionIndex]
        //                     });
        //                 }

        //                 const formattedSpec = {
        //                     information,
        //                     name: `${spec1Options[rowIndex]} ${spec2Options[optionIndex] || ''}`.trim(),
        //                     description: "",
        //                     price: option.price || 0,
        //                     cost: 0,
        //                     discount_enabled: option.discount || false,
        //                     stock: option.stock === 'limited' ? (option.stockAmount || 0) : 0,
        //                     unlimited_stock: option.stock === 'unlimited'
        //                 };
        //                 specs.push(formattedSpec);
        //             });
        //         } else {
        //             // 單一規格情況
        //             const information = {
        //                 tier_variations: [
        //                     {
        //                         name: spec1Name,
        //                         value: spec1Options[rowIndex]
        //                     }
        //                 ]
        //             };

        //             const formattedSpec = {
        //                 information,
        //                 name: spec1Options[rowIndex] || '',
        //                 description: "",
        //                 price: row.price || 0,
        //                 cost: 0,
        //                 discount_enabled: row.discount || false,
        //                 stock: row.stock === 'limited' ? (row.stockAmount || 0) : 0,
        //                 unlimited_stock: row.stock === 'unlimited'
        //             };
        //             specs.push(formattedSpec);
        //         }
        //     });
        // }

        // 處理一般折扣
        // if (values.discount?.type === 'member') {
        //     discounts.push({
        //         name: '會員折扣',
        //         description: `會員${values.discount.value}折優惠`,
        //         discount: values.discount.value || 0,
        //         discount_percentage: values.discount.percentOff || 0,
        //         level: 1, // 會員等級
        //         valid_from: values.discount?.period?.[0]?.format('YYYY-MM-DD') || null,
        //         valid_until: values.discount?.period?.[1]?.format('YYYY-MM-DD') || null
        //     });
        // }

        // 處理晚鳥優惠
        // if (values.lateDiscount?.type === 'late') {
        //     discounts.push({
        //         name: '晚鳥優惠',
        //         description: `晚鳥${values.lateDiscount.value}折優惠`,
        //         discount: values.lateDiscount.value || 0,
        //         discount_percentage: values.lateDiscount.percentOff || 0,
        //         level: 0, // 不需要會員等級
        //         valid_from: values.lateDiscount?.period?.[0]?.format('YYYY-MM-DD') || null,
        //         valid_until: values.lateDiscount?.period?.[1]?.format('YYYY-MM-DD') || null
        //     });
        // }

        // 將規格資料加入 formattedData
        // formattedData.specs = specs;
        
        // 將折扣資料加入 formattedData
        // formattedData.discount = discounts;
        // formattedData.profit_share = values.profitShare || 0;
        // formattedData.bonus_points = values.bonusPoints || 0;
        
        console.log('表單提交數據:', formattedData);
        const res = await createOrganizationAPI(formattedData);
        
        if (res.status === 200) {
            const { data } = res;
            const id = data.id;
            const verifyRes = await verifyOrganizationAPI(id);
            if (verifyRes.status === 200) {
                message.success('organization created successfully');
                window.location.href = '/organization';
            } else {
                message.error('organization creation failed');
            }

            // 修改這裡：循環處理所有的 specs
            // try {
            //     for (const spec of specs) {
            //         await createSpecAPI(id, spec);
            //     }
            //     message.success('服務和規格創建成功');
            // } catch (error) {
            //     console.error('規格創建失敗:', error);
            //     message.error('部分規格創建失敗');
            // }

            // try {
            //     for (const discount of discounts) {
            //         console.log('discount:', discount);
            //         await createDiscountAPI(id, discount);
            //     }
            //     message.success('服務和折扣創建成功');
            // } catch (error) {
            //     console.error('折扣創建失敗:', error);
            //     message.error('折扣創建失敗');
            // }

        } else {
            message.error('organization creation failed');
        }

    } catch (error) {
        console.error('表單提交失敗:', error);
        message.error('organization creation failed');
    }
  },

  scrollToTab: (key, formRef) => {
    const element = document.getElementById(key);
    if (element) {
      const container = formRef.current.closest('.overflow-auto');
      if (container) {
        container.scrollTo({
          top: element.offsetTop - 300,
          behavior: 'smooth'
        });
      }
    }
  }
};



export { useFormScroll, formLogic };

// 根據環境變數選擇配置
const isPanco = process.env.REACT_APP_PANCO_E_COMMERCE === 'true';
const isStag5 = process.env.REACT_APP_STAG5_E_COMMERCE === 'true';


export const organizationFormConfig = isPanco ? pancoConfig : isStag5 ? stag5Config : null;
