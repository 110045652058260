const pancoConfig = {
  labels: {
    selectUser: '請選擇一位用戶',
    selectService: '請選擇一筆服務',
    info: '資訊',
    popular: '熱門',
    instantUse: '即買即用',
    freeCancellation: '15天前免費取消',
    userInfo: '用戶資訊',
    serviceDesc: '服務說明',
    otherInfo: '其他資訊',
    notice: '注意事項',
    orderInfo: '訂單資訊',
    specSelection: '規格選擇',
    edit: '編輯'
  }
}

const stag5Config = {
  labels: {
    selectUser: 'Please select a user',
    selectService: 'Please select a service',
    info: 'Information',
    popular: 'Popular',
    instantUse: 'Instant Use',
    freeCancellation: '15-day Free Cancellation',
    userInfo: 'Basic Information',
    serviceDesc: 'Service Description',
    otherInfo: 'Other Information',
    notice: 'Notice',
    orderInfo: 'Order Information',
    specSelection: 'Specification',
    edit: 'Edit'
  }
}

// 根據環境變數選擇配置
const isPanco = process.env.REACT_APP_PANCO_E_COMMERCE === 'true';
const isStag5 = process.env.REACT_APP_STAG5_E_COMMERCE === 'true';

const detailConfig = isPanco ? pancoConfig : isStag5 ? stag5Config : null;

export default detailConfig;
