import React, { useContext, useState, useEffect } from 'react';
import { Table, Input, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const DemandTable = ({ columns, data, onRowClick, loading }) => {
  const [searchText, setSearchText] = useState('');
  const [selectedProvider, setSelectedProvider] = useState('all');
  const [selectedStatus, setSelectedStatus] = useState('all');

  const statusOptions = [
    { value: 'all', label: '全部' },
    { value: 'pending', label: '待審核' },
    { value: 'approved', label: '已審核' },
  ];

  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    const filtered = data.filter(item => {
      const statusMatch = selectedStatus === 'all' ? true : item.auditStatus === selectedStatus;
      
      const searchMatch = searchText === '' ? true : 
        Object.values(item).some(val => 
          val && val.toString().toLowerCase().includes(searchText.toLowerCase())
        );

      return statusMatch && searchMatch;
    });

    setFilteredData(filtered);
  }, [data, searchText, selectedStatus]);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleStatusChange = (value) => {
    setSelectedStatus(value);
  };


  return (
    <div className="flex flex-col w-full h-full">

      <div className="flex space-x-4 mb-4">
      <Select
          className="w-40"
          placeholder="狀態"
          options={statusOptions}
          onChange={handleStatusChange}
          allowClear
          value={selectedStatus}
        />
        <Input
          className="w-80"
          placeholder="Search"
          prefix={<SearchOutlined />}
          onChange={handleSearch}
        />
        
      </div>
      <div className="flex-1 overflow-auto">
        <Table
          loading={loading}
          columns={columns}
          dataSource={filteredData}
          pagination={{
            pageSize: 10,
            showSizeChanger: true,
            showQuickJumper: true,
          }}
          scroll={{ x: 2000 }}
          onRow={(record) => ({
            onClick: () => onRowClick(record),
            className: 'cursor-pointer hover:bg-gray-50'
          })}
        />
      </div>
    </div>
  );
};

export default DemandTable;
