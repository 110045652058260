import { render } from '@testing-library/react';
import { Tag } from 'antd';
import moment from 'moment';

const pancoConfig = {
  page: [
      {
          title: "會員管理",
      }
  ],
  columns: [
      {
          title: "會員序號",
          dataIndex: "id",
          width: "150px",
          fixed: "left",
          render: (id) => id.slice(0, 8)
        },
        {
          title: "會員名稱",
          dataIndex: "nickname",
          width: "180px",
          fixed: "left",
          render: (text, record) => (
            <div className="flex items-center">
              <img
                src={record.picture || "/images/user.png"}
                alt="會員頭像"
                className="w-6 h-6 object-cover rounded-full mr-2"
              />
              <span className={!text && !record.first_name && !record.last_name ? "text-gray-400" : ""}>{text || `${record.first_name || ''} ${record.last_name || ''}`.trim() || 'Unset'}</span>          </div>
          ),
        },
        {
          title: "綁定美業老師",
          dataIndex: "phone",
          width: "120px",
          render: (phone) => <span className={!phone ? "text-gray-400" : ""}>{phone || 'Unset'}</span>  },
        {
          title: "美業老師數量",
          dataIndex: "email",
          width: "180px",
          render: (email) => <span className={!email ? "text-gray-400" : ""}>{email || 'Unset'}</span>  },
        {
          title: "會員等級",
          dataIndex: "level",
          width: "100px",
          render: (level) => (
            <Tag color={level > 0 ? "gold" : "blue"}>Level {level}</Tag>
          ),
        },
        {
          title: "性別",
          dataIndex: "created_at",
          width: "130px",
          render: (date) => moment(date).format("YYYY-MM-DD"),
        },
        {
          title: "社群帳號",
          dataIndex: "sso_info",
          width: "120px",
          render: (sso) => {
            if (!sso || sso.length === 0) return <span className="text-gray-400">Unset</span>;
            return sso[0].provider;
          }
        },
        {
          title: "標籤",
          dataIndex: "tags",
          width: "200px",
          render: (tags, record) => {
            if (!tags || tags.length === 0) {
              return <Tag color="default">Unset</Tag>;
            }

            return (
              <div className="flex flex-wrap gap-1">
                {tags.map((tag, index) => (
                  <Tag key={index} color="blue">{tag}</Tag>
                ))}
              </div>
            );
          },
        },
        {
          title: "會員生日",
          dataIndex: "birthday",
          width: "200px",
          render: (birthday) => (
            <span className={!birthday ? "text-gray-400" : ""}>
              {birthday ? moment(birthday).format("YYYY-MM-DD") : "Unset"}
            </span>
          ),
        },
        {
          title: "會員電話",
          dataIndex: "phone",
          width: "200px",
          render: (phone) => (
            <span className={!phone ? "text-gray-400" : ""}>
              {phone || "Unset"}
            </span>
          ),
        },
        {
          title: "會員Email",
          dataIndex: "email",
          width: "200px",
          render: (email) => (
            <span className={!email ? "text-gray-400" : ""}>
              {email || "Unset"}
            </span>
          ),
        },
        {
          title: "會員來源",
          dataIndex: "source",
          width: "200px",
          render: (source) => (
            <span className={!source ? "text-gray-400" : ""}>
              {source || "Unset"}
            </span>
          ),
        },
        {
          title: "累積消費金額",
          dataIndex: "total_spending",
          width: "200px",
          render: (amount) => (
            <span className={!amount ? "text-gray-400" : ""}>
              {amount ? `$${amount.toLocaleString()}` : "Unset"}
            </span>
          ),
        },
        {
          title: "累積消費次數",
          dataIndex: "order_count",
          width: "200px",
          render: (count) => (
            <span className={!count ? "text-gray-400" : ""}>
              {count || "0"}
            </span>
          ),
        },
        {
          title: "累積紅利點數",
          dataIndex: "points",
          width: "200px",
          render: (points) => (
            <span className={!points ? "text-gray-400" : ""}>
              {points || "0"}
            </span>
          ),
        },
        {
          title: "最後一次消費",
          dataIndex: "last_order_date",
          width: "200px",
          render: (date) => (
            <span className={!date ? "text-gray-400" : ""}>
              {date ? moment(date).format("YYYY-MM-DD") : "Unset"}
            </span>
          ),
        },
        {
          title: "加入日期",
          dataIndex: "created_at",
          width: "200px",
          render: (date) => moment(date).format("YYYY-MM-DD"),
        },
        {
          title: "最近一次上線日期",
          dataIndex: "last_login",
          width: "200px",
          render: (date) => (
            <span className={!date ? "text-gray-400" : ""}>
              {date ? moment(date).format("YYYY-MM-DD HH:mm") : "Unset"}
            </span>
          ),
        },
        {
          title: "歷史服務訂單",
          dataIndex: "service_orders",
          width: "200px",
          render: (orders) => (
            <span className={!orders?.length ? "text-gray-400" : ""}>
              {orders?.length || "0"}
            </span>
          ),
        },
        {
          title: "歷史商品訂單",
          dataIndex: "product_orders",
          width: "200px",
          render: (orders) => (
            <span className={!orders?.length ? "text-gray-400" : ""}>
              {orders?.length || "0"}
            </span>
          ),
        },
        {
          title: "開放狀態",
          dataIndex: "enabled",
          width: "90px",
          align: "center",
          fixed: "right",
          render: (enabled) => (
            <span
              className={`badge rounded-full px-2 py-1 text-white ${enabled ? "bg-[#67BE5F]" : "bg-[#B6B3A7]"
                }`}
            >
              {enabled ? '開放' : '關閉'}
            </span>
          ),
        },
  ],
  formFields: [
      { name: 'name', label: '服務名稱', type: 'text', required: true },
      { name: 'price', label: '價格', type: 'number', required: true },
      { name: 'description', label: '描述', type: 'textarea', required: false },
  ],
};

const stag5Config = {
    page: [
        {
            title: "Member Management",
        }
    ],
    columns: [
        {
            title: "Member ID",
            dataIndex: "id",
            width: "150px",
            fixed: "left",
            render: (id) => id.slice(0, 8)
          },
          {
            title: "Member Name",
            dataIndex: "nickname",
            width: "180px",
            fixed: "left",
            render: (text, record) => (
              <div className="flex items-center">
                <img
                  src={record.picture || "/images/user.png"}
                  alt="員頭像"
                  className="w-6 h-6 object-cover rounded-full mr-2"
                />
                <span className={!text && !record.first_name && !record.last_name ? "text-gray-400" : ""}>{text || `${record.first_name || ''} ${record.last_name || ''}`.trim() || 'Unset'}</span>          </div>
            ),
          },
          {
            title: "LEVEL",
            dataIndex: "level",
            width: "100px",
            render: (level) => (
              <Tag color={level > 0 ? "gold" : "blue"}>Level {level}</Tag>
            ),
          },
          {
            title: "GENDER",
            dataIndex: "gender",
            width: "100px",
            render: (gender) => <span className={!gender ? "text-gray-400" : ""}>{gender || 'Unset'}</span>  
          },
          {
            title: "SOCIAL ACCOUNT",
            dataIndex: "sso_info",
            width: "150px",
            render: (sso) => {
              if (!sso || sso.length === 0) return <span className="text-gray-400">Unset</span>;
              return sso[0].provider;
            }
          },
          {
            title: "TAG",
            dataIndex: "tags",
            width: "200px",
            render: (tags, record) => {
              if (!tags || tags.length === 0) {
                return <Tag color="default">Unset</Tag>;
              }

              return (
                <div className="flex flex-wrap gap-1">
                  {tags.map((tag, index) => (
                    <Tag key={index} color="blue">{tag}</Tag>
                  ))}
                </div>
              );
            },
          },
          {
            title: "BIRTH DATE",
            dataIndex: "birthday",
            width: "200px",
            render: (birthday) => (
              <span className={!birthday ? "text-gray-400" : ""}>
                {birthday ? moment(birthday).format("YYYY-MM-DD") : "Unset"}
              </span>
            ),
          },
          {
            title: "PHONE NUMBER",
            dataIndex: "phone",
            width: "150px",
            render: (phone) => <span className={!phone ? "text-gray-400" : ""}>{phone || 'Unset'}</span>
          },
          {
            title: "EMAIL",
            dataIndex: "email",
            width: "120px",
            render: (email) => <span className={!email ? "text-gray-400" : ""}>{email || 'Unset'}</span>  
          },
          {
            title: "SOURCE",
            dataIndex: "source",
            width: "120px",
            render: (source) => <span className={!source ? "text-gray-400" : ""}>{source || 'Unset'}</span>  
          },
          {
            title: "TOTAL SPENDING AMOUNT",
            dataIndex: "total_spending",
            width: "210px",
            render: (amount) => <span className={!amount ? "text-gray-400" : ""}>{amount ? `$${amount.toLocaleString()}` : "Unset"}</span>
          },
          {
            title: "TOTAL SPENDING COUNT",
            dataIndex: "order_count",
            width: "210px",
            render: (count) => <span className={!count ? "text-gray-400" : ""}>{count || "0"}</span>
          },
          {
            title: "LAST PURCHASE",
            dataIndex: "last_order_date",
            width: "180px",
            render: (date) => <span className={!date ? "text-gray-400" : ""}>{date ? moment(date).format("YYYY-MM-DD") : "Unset"}</span>
          },
          {
            title: "JOIN DATE",
            dataIndex: "created_at",
            width: "130px",
            render: (date) => moment(date).format("YYYY-MM-DD"),
          },
          {
            title: "LAST ONLINE DATE",
            dataIndex: "last_login",
            width: "210px",
            render: (date) => <span className={!date ? "text-gray-400" : ""}>{date ? moment(date).format("YYYY-MM-DD HH:mm") : "Unset"}</span>
          },
          {
            title: "SERVICE ORDERS",
            dataIndex: "service_orders",
            width: "180px",
            render: (orders) => <span className={!orders?.length ? "text-gray-400" : ""}>{orders?.length || "0"}</span>
          },
          {
            title: "PRODUCT ORDERS",
            dataIndex: "product_orders",
            width: "180px",
            render: (orders) => <span className={!orders?.length ? "text-gray-400" : ""}>{orders?.length || "0"}</span>
          },
          {
            title: "STATUS",
            dataIndex: "enabled",
            width: "90px",
            align: "center",
            fixed: "right",
            render: (enabled) => (
              <span
                className={`badge rounded-full px-2 py-1 text-white ${enabled ? "bg-[#67BE5F]" : "bg-[#B6B3A7]"
                  }`}
              >
                {enabled ? 'OPEN' : 'CLOSE'}
              </span>
            ),
          },
          
    ],
    formFields: [
        { name: 'name', label: '服務名稱', type: 'text', required: true },
        { name: 'price', label: '價格', type: 'number', required: true },
        { name: 'description', label: '描述', type: 'textarea', required: false },
    ]
};

// 根據環境變數選擇配置
const isStag5 = process.env.REACT_APP_STAG5_E_COMMERCE === 'true';
const isPanco = process.env.REACT_APP_PANCO_E_COMMERCE === 'true';

export const userConfig = isPanco ? pancoConfig : isStag5 ? stag5Config : null;
