import React, { useEffect, useState } from "react";
import { Button, Table } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { organizationConfig } from "@/app/ams/config/organization/organizationConfig";
import Statistics from "@/app/ams/components/Statistics";
import Detail from "@/app/ams/components/Detail";
import { useAmsContext } from '@/app/ams/context/AmsContext';
import Form from "@/app/ams/components/Form";
import { Link } from "react-router-dom";
import { HomeOutlined } from "@ant-design/icons";
import { useFormScroll, formLogic, organizationFormConfig } from "@/app/ams/config/organization/organizationFormConfig";
import ToolBar from "@/app/ams/components/ToolBar";
import { statisticsConfig } from "@/app/ams/config/statistics/statisticsConfig";

export default function Organization() {
  const { showForm, setShowForm, selectedOrganization, setSelectedOrganization, organizations, loading, fetchOrganizations } = useAmsContext();
  const [showOrderManagement, setShowOrderManagement] = useState(false);
  const totalOrganizations = organizations?.length || 0;
  const availableOrganizations = organizations?.filter(organization => organization.status === 'On Sale')?.length || 0;
  const lowStockWarnings = organizations?.filter(organization => organization.status === 'Low Stock')?.length || 0;
  const onPromotion = organizations?.filter(organization => organization.status === 'On Promotion')?.length || 0;
  const drafts = organizations?.filter(organization => organization.status === 'Draft')?.length || 0;

  useEffect(() => {
    fetchOrganizations();
  }, []);

  useEffect(() => {
    if (organizations?.length > 0 && !selectedOrganization) {
      const firstOrganization = organizations[0];
      const organizationWithImage = {
        ...firstOrganization,
        imageUrl: firstOrganization?.blobs?.[0]?.url || '/images/default-organization.jpg',
        specs: firstOrganization.specs || [],
        description: firstOrganization.description || '',
        notification: firstOrganization.notification || '',
        status: firstOrganization.status || 'On Sale',
        provider: firstOrganization.provider || '',
        category: firstOrganization.category || {},
        tags: firstOrganization.tags || [],
        recommendation: firstOrganization.recommendation || null,
        publishDate: firstOrganization.publishDate || null
      };
      setSelectedOrganization(organizationWithImage);
    }
  }, [organizations, selectedOrganization]);

  useEffect(() => {
    console.log('organizations', organizations);
  }, [organizations]);

  const handleShowForm = () => {
    setShowForm(true);
  };

  const handleRowClick = (record) => {
    if (selectedOrganization?.key !== record.key) {
      const organizationWithImage = {
        ...record,
        imageUrl: record?.blobs?.[0]?.url || '/images/default-organization.jpg',
        specs: record.specs || [],
        description: record.description || '',
        notification: record.notification || '',
        status: record.status || 'On Sale',
        provider: record.provider || '',
        category: record.category || {},
        tags: record.tags || [],
        recommendation: record.recommendation || null,
        publishDate: record.publishDate || null
      };
      setSelectedOrganization(organizationWithImage);
    }
  };

  const BreadcrumbNav = () => (
    <div className="flex flex-col space-y-2 mb-4 p-4">
      <div className="text-lg font-bold">{organizationFormConfig.organization.title}</div>

      <div className="flex items-center text-sm">
        <Link to="/" className="text-green-600">
          <HomeOutlined className="mr-1" />
        </Link>
        <span className="mx-2 text-gray-500">/</span>
        <span
          className="text-green-600 cursor-pointer"
          onClick={() => window.location.href = '/organization'}
        >
          {organizationFormConfig.organization.title}
        </span>
        <span className="mx-2 text-gray-500">/</span>
        <span className="text-gray-500">{organizationFormConfig.organization.add}</span>
      </div>
    </div>
  );




  return (
    <div className="flex flex-col items-center justify-start w-full h-screen overflow-auto">
      <ToolBar />
      <div className="flex flex-col w-full h-[calc(100vh-64px)]">

        <div className="flex-1 flex overflow-auto">
          {showForm ? (
            <>
              <div className="flex w-full space-x-4 overflow-auto">
                <div className="flex-1 overflow-hidden">
                  <BreadcrumbNav />
                  <Form formConfig={organizationFormConfig} formLogic={formLogic} useFormScroll={useFormScroll} title={organizationFormConfig.organization.title} draftText={organizationFormConfig.organization.draft} publishText={organizationFormConfig.organization.publish} />
                </div>
                {/* <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
                  <Detail />
                </div> */}
              </div>
            </>
          ) : (
            <>
              <div className="flex w-full overflow-auto bg-gray-50">
                <div className="flex-1 flex flex-col shadow min-w-0 overflow-auto h-full p-4">
                  <div className="flex justify-between items-center mb-4">
                    <div className="text-lg font-bold">{organizationConfig?.page?.[0]?.title || "組織管理"}</div>
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={handleShowForm}
                      className="bg-[#ADDEAC] hover:bg-[#45a049] text-black hover:!text-black"
                    >
                      {organizationConfig?.page?.[0]?.addButton || "新增組織"}
                    </Button>
                  </div>
                  <Statistics total={totalOrganizations} available={availableOrganizations} lowStockWarnings={lowStockWarnings} onPromotion={onPromotion} drafts={drafts} totalTitle={statisticsConfig?.statistics?.organization?.total?.title || "總組織數"} availableTitle={statisticsConfig?.statistics?.organization?.available?.title || "上架組織數"} />
                  <Table
                    columns={organizationConfig.columns({
                      setShowOrderManagement,
                      setSelectedOrganization,
                      organizations,
                    })}
                    dataSource={organizations.map(org => ({
                      ...org,
                      key: org.id || org.key
                    }))}
                    loading={loading}
                    pagination={{
                      pageSize: 10,
                      className: "sticky bottom-0 pb-2",
                    }}
                    onRow={(record) => ({
                      onClick: () => handleRowClick(record),
                      className: record.key === (selectedOrganization?.key ?? '') ? "bg-gray-100" : "",
                    })}
                    rowClassName={(record) => `cursor-pointer ${record.key === (selectedOrganization?.key ?? '') ? "bg-gray-100" : ""}`}
                    scroll={{ x: 2000 }}
                    size="small"
                    className="[&_.ant-table-thead>tr>th]:bg-[#E0F0E0]"
                  />
                </div>
                <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
                  <Detail
                    data={selectedOrganization}
                    type="organization"
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
