import React from 'react'
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { ReactComponent as ExploreIcon } from './assets/explore_tab_icon.svg';
import { ReactComponent as ChatIcon } from './assets/chat_tab_icon.svg';
import { ReactComponent as ManagementIcon } from './assets/management_tab_icon.svg';
import { ReactComponent as DemandIcon } from './assets/cms_demand_icon.svg';
import { ReactComponent as ServiceIcon } from './assets/cms_service_icon.svg';
import { ReactComponent as ProductIcon } from './assets/cms_product_icon.svg';
import { ReactComponent as UserIcon } from './assets/cms_user_icon.svg';
import { ReactComponent as OrganizationIcon } from './assets/cms_organization_icon.svg';
import Logo from '../logo/Logo';
import { FloatingPortal, useFloating, useHover, useInteractions, offset, FloatingArrow, arrow } from '@floating-ui/react';

const useTooltip = (label) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const arrowRef = React.useRef(null);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'right',
    middleware: [
      offset(10),
      arrow({ element: arrowRef })
    ],
  });

  const hover = useHover(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

  const TooltipContent = React.useMemo(() => {
    return isOpen ? (
      <FloatingPortal>
        <div
          ref={refs.setFloating}
          style={{
            ...floatingStyles,
            zIndex: 1000
          }}
          {...getFloatingProps()}
          className="bg-gray-800 text-white px-2 py-1 rounded text-sm"
        >
          {label}
          <FloatingArrow
            ref={arrowRef}
            context={context}
            className="fill-gray-800"
          />
        </div>
      </FloatingPortal>
    ) : null;
  }, [isOpen, refs.setFloating, floatingStyles, getFloatingProps, context, label, arrowRef]);

  return {
    refs,
    getReferenceProps,
    TooltipContent
  };
};

const NavBar = () => {
  const location = useLocation();
  const render_official_site = process.env.REACT_APP_E_OFFICIAL_SITE_MODULE === 'true' || false;
  const isPanco = process.env.REACT_APP_PANCO_E_COMMERCE === 'true' || false;
  const isStag = process.env.REACT_APP_STAG5_E_COMMERCE === 'true' || false;

  const exploreTooltip = useTooltip('探索');
  const chatTooltip = useTooltip('聊天');
  const managementTooltip = useTooltip('後台管理');
  const demandTooltip = useTooltip('需求管理');
  const serviceTooltip = useTooltip('服務管理');
  const productTooltip = useTooltip('商品管理');
  const userTooltip = useTooltip('用戶管理');
  const organizationTooltip = useTooltip('店家管理');
  return (
    <div className="flex flex-col items-center justify-start min-h-screen min-w-24 w-24 pt-4 gap-4 bg-bgDark">
      <a href="/" className='w-[45px] h-[45px] flex justify-center items-center overflow-hidden' >
        <Logo type="NAVBAR" />
      </a>
      <a href="/explore"
        ref={exploreTooltip.refs.setReference}
        {...exploreTooltip.getReferenceProps()}
        className={classNames({
          'border-primary': location.pathname.includes("/explore"),
          'flex w-10 h-10 items-center justify-center cursor-pointer border-[1px] border-borderDark hover:border-primaryMedium rounded-full': true,
        })}>
        <ExploreIcon />
        {exploreTooltip.TooltipContent}
      </a>

      <a href="/chat"
        ref={chatTooltip.refs.setReference}
        {...chatTooltip.getReferenceProps()}
        className={classNames({
          'border-primary': location.pathname.includes("/chat"),
          'flex w-10 h-10 items-center justify-center cursor-pointer border-[1px] border-borderDark hover:border-primaryMedium rounded-full': true,
        })}>
        <ChatIcon />
        {chatTooltip.TooltipContent}
      </a>
      {render_official_site && (
        <>
          <a href="/management"
            ref={managementTooltip.refs.setReference}
            {...managementTooltip.getReferenceProps()}
            className={classNames({
              'border-primary': location.pathname.includes("/management"),
              'flex w-10 h-10 items-center justify-center cursor-pointer border-[1px] border-borderDark hover:border-primaryMedium rounded-full': true,
            })}>
            <ManagementIcon />
            {managementTooltip.TooltipContent}
          </a>
          <a href="/demand"
            ref={demandTooltip.refs.setReference}
            {...demandTooltip.getReferenceProps()}
            className={classNames({
              'border-primary': location.pathname.includes("/demand"),
              'flex w-10 h-10 items-center justify-center cursor-pointer border-[1px] border-borderDark hover:border-primaryMedium rounded-full': true,
            })}>
            <DemandIcon />
            {demandTooltip.TooltipContent}
          </a>
        </>
      )}
      {isPanco && (
        <>
        <a href="/user"
            ref={userTooltip.refs.setReference}
            {...userTooltip.getReferenceProps()}
            className={classNames({
              'border-primary': location.pathname.includes("/user"),
              'flex w-10 h-10 items-center justify-center cursor-pointer border-[1px] border-borderDark hover:border-primaryMedium rounded-full': true,
            })}>
            <UserIcon />
            {userTooltip.TooltipContent}
          </a>
          <a href="/organization"
            ref={organizationTooltip.refs.setReference}
            {...organizationTooltip.getReferenceProps()}
            className={classNames({
              'border-primary': location.pathname.includes("/organization"),
              'flex w-10 h-10 items-center justify-center cursor-pointer border-[1px] border-borderDark hover:border-primaryMedium rounded-full': true,
            })}>
            <OrganizationIcon />
            {organizationTooltip.TooltipContent}
          </a>
          <a href="/service"
            ref={serviceTooltip.refs.setReference}
            {...serviceTooltip.getReferenceProps()}
            className={classNames({
              'border-primary': location.pathname.includes("/service"),
              'flex w-10 h-10 items-center justify-center cursor-pointer border-[1px] border-borderDark hover:border-primaryMedium rounded-full': true,
            })}>
            <ServiceIcon />
            {serviceTooltip.TooltipContent}
          </a>
          <a href="/product"
            ref={productTooltip.refs.setReference}
            {...productTooltip.getReferenceProps()}
            className={classNames({
              'border-primary': location.pathname.includes("/product"),
              'flex w-10 h-10 items-center justify-center cursor-pointer border-[1px] border-borderDark hover:border-primaryMedium rounded-full': true,
            })}>
            <ProductIcon />
            {productTooltip.TooltipContent}
          </a>
        </>
      )}

      {isStag && (
        <>
          <a href="/user"
            ref={userTooltip.refs.setReference}
            {...userTooltip.getReferenceProps()}
            className={classNames({
              'border-primary': location.pathname.includes("/user"),
              'flex w-10 h-10 items-center justify-center cursor-pointer border-[1px] border-borderDark hover:border-primaryMedium rounded-full': true,
            })}>
            <UserIcon />
            {userTooltip.TooltipContent}
          </a>
          <a href="/organization"
            ref={organizationTooltip.refs.setReference}
            {...organizationTooltip.getReferenceProps()}
            className={classNames({
              'border-primary': location.pathname.includes("/organization"),
              'flex w-10 h-10 items-center justify-center cursor-pointer border-[1px] border-borderDark hover:border-primaryMedium rounded-full': true,
            })}>
            <OrganizationIcon />
            {organizationTooltip.TooltipContent}
          </a>
          <a href="/service"
            ref={serviceTooltip.refs.setReference}
            {...serviceTooltip.getReferenceProps()}
            className={classNames({
              'border-primary': location.pathname.includes("/service"),
              'flex w-10 h-10 items-center justify-center cursor-pointer border-[1px] border-borderDark hover:border-primaryMedium rounded-full': true,
            })}>
            <ServiceIcon />
            {serviceTooltip.TooltipContent}
          </a>
          <a href="/product"
            ref={productTooltip.refs.setReference}
            {...productTooltip.getReferenceProps()}
            className={classNames({
              'border-primary': location.pathname.includes("/product"),
              'flex w-10 h-10 items-center justify-center cursor-pointer border-[1px] border-borderDark hover:border-primaryMedium rounded-full': true,
            })}>
            <ProductIcon />
            {productTooltip.TooltipContent}
          </a>
        </>
      )}

      <div
        className="text-xs text-bgMedium"
      >
        v-1129-01
      </div>

    </div>
  )
}

export default NavBar
