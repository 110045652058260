import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { HomeOutlined, DownloadOutlined } from '@ant-design/icons';
import DemandDetail from '@/app/cms/components/Official/DemandDetail';
import DemandTable from '@/app/cms/components/Official/DemandTable';
import { demandColumns } from '@/app/cms/data/officialModule/demandColumns';
import { fetchOfficialProductsAPI } from '@/app/cms/services/api';
import { Button, message } from 'antd';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export default function Demand() {
    const [demands, setDemands] = useState([]);
    const [selectedDemand, setSelectedDemand] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await fetchOfficialProductsAPI();
                console.log('API response:', response);
                
                if (response?.data?.items) {
                    const formattedDemands = response.data.items.map(item => ({
                        key: item.id,
                        id: item.id,
                        created_at: item.created_at,
                        information: item.information || {},
                        name: item.information?.basic?.name || 'Unset',
                        phone: item.information?.basic?.phone || 'Unset',
                        funeral: item.information?.basic?.funeral || 'Unset',
                        gender: item.information?.basic?.gender || 'Unset',
                        birthday: item.information?.basic?.birthday || 'Unset',
                        date: item.information?.basic?.date || 'Unset',
                        publicScript: item.information?.ceremony?.public_sacrifice || 'Unset',
                        familyScript: item.information?.ceremony?.family_sacrifice || 'Unset',
                        farewellScript: item.information?.ceremony?.travel || 'Unset',
                        auditStatus: 'pending',
                        blobs: item.blobs || [],
                    }));
                    console.log('formattedDemands:', formattedDemands);
                    setDemands(formattedDemands);
                    if (formattedDemands.length > 0) {
                        setSelectedDemand(formattedDemands[0]);
                    }
                }
                setLoading(false);
            } catch (error) {
                console.error('獲取需求單數據失敗:', error);
            }
        };
        fetchData();
    }, []);

    // 新增面包屑導航組件
    const BreadcrumbNav = () => (
        <div className="flex flex-col space-y-2 mb-4 p-4">
            <div className="text-[24px] font-bold">
                需求單管理
            </div>
            <div className="flex items-center text-sm">
                <Link to="/" className="text-green-600">
                    <HomeOutlined className="mr-1" />
                </Link>
                <span className="mx-2 text-gray-500">/</span>
                <span className="text-gray-500">需求單管理</span>
            </div>
        </div>
    );

    const handleExport = async () => {
        try {
            // 創建一個新的 ZIP 文件
            const zip = new JSZip();
            
            // 添加需求資料為 JSON 文件
            const demandsJson = JSON.stringify(demands, null, 2);
            zip.file('demands.json', demandsJson);

            // 為每個需求創建一個資料夾
            for (const demand of demands) {
                const demandFolder = zip.folder(`demand_${demand.id}`);

                // 添加圖片
                if (demand.blobs) {
                    for (let i = 0; i < demand.blobs.length; i++) {
                        const blob = demand.blobs[i];
                        if (blob.content_type.includes('image')) {
                            try {
                                const response = await fetch(blob.url);
                                const imageBlob = await response.blob();
                                demandFolder.file(
                                    `image_${i + 1}.${blob.content_type.split('/')[1]}`, 
                                    imageBlob
                                );
                            } catch (error) {
                                console.error(`下載圖片失敗: ${blob.url}`, error);
                            }
                        }
                        // 如果是音訊檔
                        else if (blob.content_type.includes('audio')) {
                            try {
                                const response = await fetch(blob.url);
                                const audioBlob = await response.blob();
                                demandFolder.file(
                                    `audio_${i + 1}.${blob.content_type.split('/')[1]}`, 
                                    audioBlob
                                );
                            } catch (error) {
                                console.error(`下載音訊失敗: ${blob.url}`, error);
                            }
                        }
                    }
                }
            }

            // 生成 ZIP 文件並下載
            const content = await zip.generateAsync({ type: 'blob' });
            const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
            saveAs(content, `需求單_${timestamp}.zip`);
        } catch (error) {
            console.error('匯出失敗:', error);
            message.error('匯出需求單失敗，請稍後再試');
        }
    };

    return (
        <>
            <div className="flex w-full overflow-auto bg-gray-50">
                <div className="flex-1 flex flex-col shadow min-w-0 overflow-auto h-full p-4">
                    <BreadcrumbNav />
                    <div className="space-y-4">
                        <div className="flex justify-between items-center">
                            <div>
                                {/* 如果有其他左側元素可以放這裡 */}
                            </div>
                            <Button
                                type="primary"
                                icon={<DownloadOutlined />}
                                onClick={handleExport}
                                className="bg-[#67BE5F] hover:bg-[#ADDEAC] border-[#67BE5F] hover:border-[#ADDEAC]"
                            >
                                匯出需求單
                            </Button>
                        </div>
                        
                        <DemandTable 
                            columns={demandColumns} 
                            data={demands} 
                            onRowClick={(record) => setSelectedDemand(record)}
                            loading={loading}
                        />
                    </div>
                </div>
                <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
                    <DemandDetail service={selectedDemand} emptyMessage="請選擇需求單" />
                </div>
            </div>
        </>
    );
}
