import React, { createContext, useState, useContext } from 'react';
import { fetchServicesAPI, fetchProductsAPI, getAccessToken, getServerUrl, fetchIdentityListAPI, fetchCategoriesAPI, fetchUsersAPI, fetchOrganizationsAPI } from '../services/api';

export const AmsContext = createContext({
  // Users
  users: [],
  selectedUser: null,
  loading: false,
  fetchUsers: () => {},
  setUsers: () => {},
  setSelectedUser: () => {},

  // Organizations
  organizations: [],
  selectedOrganization: null,
  fetchOrganizations: () => {},
  setOrganizations: () => {},
  setSelectedOrganization: () => {},
  

  // Services
  services: [],
  selectedService: null,
  filteredServices: [],
  fetchServices: () => {},
  setSelectedService: () => {},
  filterServices: () => {},
  
  // Products
  products: [],
  selectedProduct: null,
  filteredProducts: [],
  fetchProducts: () => {},
  setSelectedProduct: () => {},
  filterProducts: () => {},
  
  // Common
  loading: false,
  showForm: false,
  showOrderManagement: false,
  setShowForm: () => {},
  handleShowForm: () => {},
  setShowOrderManagement: () => {},
});

export const AmsProvider = ({ children }) => {
  // Users state
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  // Organizations state
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState(null);

  // Services state
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [filteredServices, setFilteredServices] = useState([]);
  
  // Products state
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState([]);
  
  // Common state
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [showOrderManagement, setShowOrderManagement] = useState(false);
  const [identityList, setIdentityList] = useState([]);
  const [categories, setCategories] = useState([]);

  // Users
  const fetchUsers = async () => {
    setLoading(true);
    try {
      const fetchedUsers = await fetchUsersAPI();
      console.log('fetchedUsers', fetchedUsers);
      const formattedUsers = Array.isArray(fetchedUsers) ? fetchedUsers.map(user => ({
        ...user,
        key: user.id
      })) : [];
      setUsers(formattedUsers);
    } catch (error) {
      console.error('Error fetching users:', error);
      setUsers([]);
    } finally {
      setLoading(false);
    }
  };

  // Organizations
  const fetchOrganizations = async () => {
    setLoading(true);
    try {
      const fetchedOrganizations = await fetchOrganizationsAPI();
      const organizationsWithKeys = fetchedOrganizations.map(org => ({
        ...org,
        key: org.id
      }));
      setOrganizations(organizationsWithKeys);
      console.log('organizationsWithKeys', organizationsWithKeys);
      
    } catch (error) {
      console.error('Error fetching organizations:', error);
      setOrganizations([]);
    } finally {
      setLoading(false);
    }
  };

  // Services
  const fetchServices = async () => {
    setLoading(true);
    try {
      const fetchedServices = await fetchServicesAPI();
      const formattedServices = fetchedServices.map(service => ({
        ...service,
        key: service.id,                    
        status: "On Sale",                  
        order: 0,                           
        memberDiscount: "無折扣",           
        earlyBirdDiscount: "無折扣",        
        imageUrl: service.blobs?.[0]?.url || '/images/default-service.jpg',
        specs: service.specs?.map(spec => ({
          ...spec,
          price: spec.price || 0,
          stock: spec.unlimited_stock ? "0" : (spec.stock?.toString() || "Unset")
        }))
      }));      
      setServices(formattedServices);      
    } catch (error) {
      console.error('Error fetching services:', error);
    } finally {
      setLoading(false);
    }
  };

  const filterServices = (status) => {
    if (!status || status === "all") {
      setFilteredServices(services);
    } else {
      const filtered = services.filter(service => service.status === status);
      setFilteredServices(filtered);
    }
  };

  // Products
  const fetchProducts = async () => {
    setLoading(true);
    try {
      const token = await getAccessToken();
      const url = await getServerUrl();
      const fetchedProducts = await fetchProductsAPI(token, url);
      const productsWithKeys = fetchedProducts.map(product => ({
        ...product,
        key: product.id
      }));
      setProducts(productsWithKeys);
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setLoading(false);
    }
  };

  const filterProducts = (status) => {
    if (!status || status === "all") {
      setFilteredProducts(products);
    } else {
      const filtered = products.filter(product => product.status === status);
      setFilteredProducts(filtered);
    }
  };

  // Common
  const handleShowForm = () => setShowForm(!showForm);

  // Save service data
  const saveServiceData = async (formData) => {
    try {
      const newService = {
        id: Date.now().toString(),
        key: Date.now().toString(),
        ...formData,
        status: "On Sale",
        order: 0,
        memberDiscount: "無折扣",
        earlyBirdDiscount: "無折扣",
        imageUrl: formData.information?.imageUrl || '/images/default-service.jpg',
        created_at: new Date().toISOString()
      };

      setServices(prevServices => [...prevServices, newService]);
      return true;
    } catch (error) {
      console.error('保存服務資料失敗:', error);
      throw error;
    }
  };

  const fetchIdentityList = async (orgId) => {
    try {
      const fetchedIdentityList = await fetchIdentityListAPI(orgId);
      console.log('fetchedIdentityList', fetchedIdentityList);
      if (Array.isArray(fetchedIdentityList)) {
        setIdentityList(fetchedIdentityList);
      } else {
        setIdentityList([]);
      }
    } catch (error) {
      console.error('獲取美容師失敗:', error);
      setIdentityList([]);
    }
  };

  const fetchCategories = async () => {
    try {
      const fetchedCategories = await fetchCategoriesAPI();
      setCategories(fetchedCategories);
    } catch (error) {
      console.error('獲取類別失敗:', error);
      setCategories([]);
    }
  };

  const value = {
    // Users
    users,
    selectedUser,
    loading,
    fetchUsers,
    setUsers,
    setSelectedUser,

    // Organizations
    organizations,
    selectedOrganization,
    fetchOrganizations,
    setOrganizations,
    setSelectedOrganization,

    // Services
    services,
    selectedService,
    filteredServices,
    fetchServices,
    setSelectedService,
    filterServices,
    saveServiceData,
    
    // Products
    products,
    selectedProduct,
    filteredProducts,
    fetchProducts,
    setSelectedProduct,
    filterProducts,
    
    // Common
    loading,
    showForm,
    showOrderManagement,
    setShowForm,
    handleShowForm,
    setShowOrderManagement,
    identityList,
    setIdentityList,
    fetchIdentityList,
    categories,
    setCategories,
    fetchCategories,
  };

  return (
    <AmsContext.Provider value={value}>
      {children}
    </AmsContext.Provider>
  );
};

export const useAmsContext = () => {
  const context = useContext(AmsContext);
  if (!context) {
    throw new Error('useAmsContext must be used within an AmsProvider');
  }
  return context;
};

export const useAms = () => {
  const context = useContext(AmsContext);
  if (!context) {
    throw new Error('useAms must be used within an AmsProvider');
  }
  return context;
};
