import { request, callApi } from '@/utils';
import { serviceConfig } from '@/app/ams/config/service/serviceConfig';
import { productConfig } from '@/app/ams/config/product/productConfig';
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

// Get auth config
export const getAuthConfig = async () => {
  try {
    const [accessToken, serverUrl] = await Promise.all([
      getAccessToken(),
      getServerUrl()
    ]);

    return {
      accessToken,
      serverUrl
    };
  } catch (error) {
    console.error('獲取認證配置失敗:', error);
    throw new Error('Failed to fetch authentication configuration');
  }
};

// Get official auth config
export const getOfficialAuthConfig = async () => {
  try {
    const [accessToken, serverUrl] = await Promise.all([
      getOfficialAccessToken(),
      getOfficialServerUrl()
    ]);

    return {
      accessToken,
      serverUrl
    };
  } catch (error) {
    console.error('獲取認證配置失敗:', error);
    throw new Error('Failed to fetch authentication configuration');
  }
};

// Get server URL
export const getServerUrl = async (debug=true) => {
  try {
    const response = await request.get(`${SERVER_URL}/private/property/ecommerce/profile?debug=${debug}`);
    return response.body.server_url;
  } catch (error) {
    console.error('獲取伺服器 URL 失敗:', error);
    throw new Error('Unable to fetch server URL');
  }
};


// Get Access Token
export const getAccessToken = async (debug=true) => {
  try {
    const response = await request.get(`${SERVER_URL}/private/property/ecommerce/jwt?debug=${debug}`);
    return response.access_token;
  } catch (error) {
    console.error('獲取存取令牌失敗:', error);
    throw new Error('Unable to fetch access token');
  }
};

// Get Access Token
export const getOfficialAccessToken = async () => {
  try {
    const response = await request.get(`${SERVER_URL}/private/property/official_site/jwt`);
    console.log('response', response.access_token);
    return response.access_token;
  } catch (error) {
    console.error('獲取存取令牌失敗:', error);
    throw new Error('Unable to fetch access token');
  }
};


// Fetch users data
export const fetchUsersAPI = async () => {
  try {
    const response = await callApi.get('/system/user/all');
    return response.data.users;
  } catch (error) {
    console.error('獲取用戶數據失敗:', error);
    throw new Error('Unable to fetch users');
  }
};


// Fetch services data
export const fetchServicesAPI = async () => {
  try {
    // 1. Check category data
    const categoriesResponse = await serviceConfig.apis.fetchCategories();
    const categories = categoriesResponse.data;
    
    // 2. Check product data for each category
    const servicesPromises = categories.map(category => 
      callApi.get(`/public/product/byCategory/${category.id}`)
    );
    
    const servicesResponses = await Promise.all(servicesPromises);
    
    // 3. Check final integrated data
    const allServices = servicesResponses.reduce((acc, response) => {
      const services = response.data.map(service => ({
        ...service,
        key: service.id,
        category: categories.find(cat => cat.id === service.category_id)
      }));
      return [...acc, ...services];
    }, []);
    
    return allServices;
    
  } catch (error) {
    console.error('獲取服務數據失敗:', error);
    throw new Error('Unable to fetch services');
  }
};

// Fetch services data
export const fetchProductsAPI = async () => {
  try {
    // 1. Check category data
    const categoriesResponse = await productConfig.apis.fetchCategories();
    const categories = categoriesResponse.data;
    
    // 2. Check product data for each category
    const productsPromises = categories.map(category => 
      callApi.get(`/public/product/byCategory/${category.id}`)
    );
    
    const productsResponses = await Promise.all(productsPromises);
    
    // 3. Check final integrated data
    const allProducts = productsResponses.reduce((acc, response) => {
      const products = response.data.map(product => ({
        ...product,
        key: product.id,
        category: categories.find(cat => cat.id === product.category_id)
      }));
      return [...acc, ...products];
    }, []);
    
    return allProducts;
    
  } catch (error) {
    console.error('獲取產品數據失敗:', error);
    throw new Error('Unable to fetch products');
  }
};


// Get official server URL
export const getOfficialServerUrl = async () => {
  try {
    const response = await request.get(`${SERVER_URL}/private/property/official_site/profile`);
    console.log('response', response.body.server_url);
    
    return response.body.server_url;
  } catch (error) {
    console.error('獲取伺服器 URL 失敗:', error);
    throw new Error('Unable to fetch server URL');
  }
};

// Update service data
export const updateServiceAPI = async (serviceId, updateData) => {
  try {
    const { name, description, notification } = updateData;
    const updates = [];
    
    // Update name
    if (name) {
      updates.push(
        callApi.patch(
          `/private/sp/${serviceId}?fields=name&value=${encodeURIComponent(name)}`
        )
      );
    }

    // Update description
    if (description) {
      updates.push(
        callApi.patch(
          `/private/sp/${serviceId}?fields=description&value=${encodeURIComponent(description)}`
        )
      );
    }

    // Update notification
    if (notification) {
      updates.push(
        callApi.patch(
          `/private/sp/${serviceId}?fields=notification&value=${encodeURIComponent(notification)}`
        )
      );
    }

    // Execute all updates concurrently
    await Promise.all(updates);
    return true;
    
  } catch (error) {
    console.error('更新服務數據失敗:', error);
    throw new Error('Unable to update service');
  }
};

// Save service data
export const createServiceAPI = async (formattedData, organizationId) => {
  try {
    const response = await callApi.post(`/private/product/${organizationId}`, formattedData);
    return response;
  } catch (error) {
    console.error('儲存服務數據失敗:', error);
    throw new Error('Unable to save service');
  }
};

// Create service spec
export const createSpecAPI = async (serviceId, specData) => {
  try {
    const response = await callApi.post(`/private/product/${serviceId}/spec`, specData);
    return response.data;
  } catch (error) {
    console.error('新增規格失敗:', error);
    throw error;
  }
};

// Create service discount
export const createDiscountAPI = async (serviceId, discountData) => {
  try {
    console.log('創建折扣前的數據檢查:', {
      serviceId,
      discountData,
      hasValidFrom: !!discountData.valid_from,
      hasValidUntil: !!discountData.valid_until,
      validFromType: typeof discountData.valid_from,
      validUntilType: typeof discountData.valid_until
    });

    // Ensure date format is correct
    const formattedData = {
      ...discountData,
      valid_from: discountData.valid_from || null,
      valid_until: discountData.valid_until || null
    };

    const response = await callApi.post(`/private/product/${serviceId}/discount`, formattedData);
    return response;
  } catch (error) {
    console.error('折扣創建錯誤:', {
      error,
      requestData: discountData,
      response: error.response?.data
    });
    throw error;
  }
};

// Fetch official products
export const fetchOfficialProductsAPI = async () => {
  try {
    const response = await callApi.get('/group/6fbba4f2-d965-452a-8ec4-75e780317b25');
    console.log('response:', response);
    return response;
  } catch (error) {
    console.error('獲取需求單數據失敗:', error);
    throw new Error('Unable to fetch demand');
  }
};

// Fetch organizations
export const fetchOrganizationsAPI = async () => {
  try {
    const response = await callApi.get('/public/organization/');
    return response.data;
  } catch (error) {
    console.error('獲取店家列表失敗:', error);
    throw error;
  }
};

// Fetch identity list
export const fetchIdentityListAPI = async (orgId) => {
  if (orgId) {
    try {
      const response = await callApi.get(`/public/identity/all/${orgId}`);
      
      if (response.data && Array.isArray(response.data)) {
        return response.data;
      }
      return [];
    } catch (error) {
      console.error('獲取美容師列表失敗:', error);
      throw error;
    }
  }
  return [];
};


// Create provider
export const createProviderAPI = async (serviceId, beautician) => {
  try {
    const response = await callApi.post(`/private/product/${serviceId}/apply?indentity_id=${beautician}`);
    return response;
  } catch (error) {
    console.error('新增美容師失敗:', error);
    throw error;
  }
};

export const fetchCategoriesAPI = async (type) => {
  try {
    const response = await callApi.get(`/public/category?product_type=${type}`);
    
    if (response.data) {
      const categoryList = response.data.map(category => ({
        id: category.id,
        name: category.name,
        product_type: category.product_type,
        category_id: category.id
      }));
      
      return categoryList;
    }
    return [];
  } catch (error) {
    console.error('獲取類別列表失敗:', error);
    throw error;
  }
};

export const fetchIdentityAPI = async (identityId) => {
  try {
    const response = await callApi.get(`/public/identity/${identityId}`);
    return response;
  } catch (error) {
    console.error('獲取身份證列表失敗:', error);
    throw error;
  }
};

// Create organization
export const createOrganizationAPI = async (organizationData) => {
  try {
    const response = await callApi.post('/private/organization/', organizationData);
    return response;
  } catch (error) {
    console.error('創建組織失敗:', error);
    throw error;
  }
};

// Verify organization
export const verifyOrganizationAPI = async (organizationId) => {
  try {
    const response = await callApi.post(`/system/organization/verify/${organizationId}`);
    return response;
  } catch (error) {
    console.error('驗證組織失敗:', error);
    throw error;
  }
}
