

const stag5Config = {
    statistics: {
        service: {
           total:{
                title: "Total Services",
                dataIndex: "total_services",
                width: "200px"
            },
            available: {
                title: "Active Services",
                dataIndex: "active_services",
                width: "200px"
            },
            lowStockWarnings: {
                title: "Low Stock Warnings",
                dataIndex: "low_stock_warnings",
                width: "200px"
            },
            onPromotion: {
                title: "On Promotion",
                dataIndex: "on_promotion",
                width: "200px"
            },
            drafts: {
                title: "Drafts",
                dataIndex: "drafts",
                width: "200px"
            }
        },
        product: {
            total: {
                title: "Total Products",
                dataIndex: "total_products",
                width: "200px"
            },
            available: {
                title: "Active Products",
                dataIndex: "active_products",
                width: "200px"
            },
            lowStockWarnings: {
                title: "Low Stock Warnings",
                dataIndex: "low_stock_warnings",
                width: "200px"
            },
            onPromotion: {
                title: "On Promotion",
                dataIndex: "on_promotion",
                width: "200px"
            },
            drafts: {
                title: "Drafts",
                dataIndex: "drafts",
                width: "200px"
            }
        },
        user: {
            total: {
                title: "Total Users",
                dataIndex: "total_users",
                width: "200px"
            },
            available: {
                title: "Active Users",
                dataIndex: "active_users",
                width: "200px"
            },
            lowStockWarnings: {
                title: "Low Stock Warnings",
                dataIndex: "low_stock_warnings",
                width: "200px"
            },
            onPromotion: {
                title: "On Promotion",
                dataIndex: "on_promotion",
                width: "200px"
            },
            drafts: {
                title: "Drafts",
                dataIndex: "drafts",
                width: "200px"
            }
        },
        organization: {
            total: {
                title: "Total Organizations",
                dataIndex: "total_organizations",
                width: "200px"
            },
            available: {
                title: "Active Organizations",
                dataIndex: "active_organizations",
                width: "200px"
            },
            lowStockWarnings: {
                title: "Low Stock Warnings",
                dataIndex: "low_stock_warnings",
                width: "200px"
            },
            onPromotion: {
                title: "On Promotion",
                dataIndex: "on_promotion",
                width: "200px"
            },
            drafts: {
                title: "Drafts",
                dataIndex: "drafts",
                width: "200px"
            }
        },
        user: {
            total: {
                title: "Total Users",
                dataIndex: "total_users",
                width: "200px"
            },
            available: {
                title: "Active Users",
                dataIndex: "active_users",
                width: "200px"
            },
            lowStockWarnings: {
                title: "Low Stock Warnings",
                dataIndex: "low_stock_warnings",
                width: "200px"
            },
            onPromotion: {
                title: "On Promotion",
                dataIndex: "on_promotion",
                width: "200px"
            },
            drafts: {
                title: "Drafts",
                dataIndex: "drafts",
                width: "200px"
            }
        },
    }
}

const pancoConfig = {
    statistics: {
        service: {
           total:{
                title: "總服務數",
                dataIndex: "total_services",
                width: "200px"
            },
            available: {
                title: "上架服務數",
                dataIndex: "active_services",
                width: "200px"
            },
            lowStockWarnings: {
                title: "庫存不足警告",
                dataIndex: "low_stock_warnings",
                width: "200px"
            },
            onPromotion: {
                title: "促銷中",
                dataIndex: "on_promotion",
                width: "200px"
            },
            drafts: {
                title: "草稿",
                dataIndex: "drafts",
                width: "200px"
            }
        },
        product: {
            total: {
                title: "總商品數",
                dataIndex: "total_products",
                width: "200px"
            },
            available: {
                title: "上架商品數",
                dataIndex: "active_products",
                width: "200px"
            },
            lowStockWarnings: {
                title: "庫存不足警告",
                dataIndex: "low_stock_warnings",
                width: "200px"
            },
            onPromotion: {
                title: "促銷中",
                dataIndex: "on_promotion",
                width: "200px"
            },
            drafts: {
                title: "草稿",
                dataIndex: "drafts",
                width: "200px"
            }
        },
        user: {
            total: {
                title: "總用戶數",
                dataIndex: "total_users",
                width: "200px"
            },
            available: {
                title: "上架用戶數",
                dataIndex: "active_users",
                width: "200px"
            },
            lowStockWarnings: {
                title: "庫存不足警告",
                dataIndex: "low_stock_warnings",
                width: "200px"
            },
            onPromotion: {
                title: "促銷中",
                dataIndex: "on_promotion",
                width: "200px"
            },
            drafts: {
                title: "草稿",
                dataIndex: "drafts",
                width: "200px"
            }
        },
        organization: {
            total: {
                title: "總組織數",
                dataIndex: "total_organizations",
                width: "200px"
            },
            available: {
                title: "上架組織數",
                dataIndex: "active_organizations",
                width: "200px"
            },
            lowStockWarnings: {
                title: "庫存不足警告",
                dataIndex: "low_stock_warnings",
                width: "200px"
            },
            onPromotion: {
                title: "促銷中",
                dataIndex: "on_promotion",
                width: "200px"
            },
            drafts: {
                title: "草稿",
                dataIndex: "drafts",
                width: "200px"
            }
        },
        user: {
            total: {
                title: "總用戶數",
                dataIndex: "total_users",
                width: "200px"
            }
        },
        available: {
            title: "上架用戶數",
            dataIndex: "active_users",
            width: "200px"
        },
        lowStockWarnings: {
            title: "庫存不足警告",
            dataIndex: "low_stock_warnings",
            width: "200px"
        },
        onPromotion: {
            title: "促銷中",
            dataIndex: "on_promotion",
            width: "200px"
        },
        drafts: {
            title: "草稿",
            dataIndex: "drafts",
            width: "200px"
        }
    }
}

const isPanco = process.env.REACT_APP_PANCO_E_COMMERCE === 'true';
const isStag5 = process.env.REACT_APP_STAG5_E_COMMERCE === 'true';

export const statisticsConfig = isPanco ? pancoConfig : isStag5 ? stag5Config : null;
