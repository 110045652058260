import React, { useEffect, useState } from "react";
import { Button, Table } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { serviceConfig } from "@/app/ams/config/service/serviceConfig";
import Statistics from "@/app/ams/components/Statistics";
import Detail from "@/app/ams/components/Detail";
import { useAmsContext } from '@/app/ams/context/AmsContext';
import Form from "@/app/ams/components/Form";
import { Link } from "react-router-dom";
import { HomeOutlined } from "@ant-design/icons";
import { useFormScroll, formLogic, serviceFormConfig } from "@/app/ams/config/service/serviceFormConfig";
import ToolBar from "@/app/ams/components/ToolBar";
import { statisticsConfig } from "@/app/ams/config/statistics/statisticsConfig";
export default function Service() {
  const { showForm, setShowForm, selectedService, setSelectedService, services, loading, fetchServices } = useAmsContext();
  const columns = serviceConfig.columns;
  const [showOrderManagement, setShowOrderManagement] = useState(false);
  const totalServices = services.length || 0;
  const availableServices = services.filter(service => service.status === 'On Sale').length || 0;
  const lowStockWarnings = services.filter(service => service.status === 'Low Stock').length || 0;
  const onPromotion = services.filter(service => service.status === 'On Promotion').length || 0;
  const drafts = services.filter(service => service.status === 'Draft').length || 0;

  useEffect(() => {
    fetchServices();
  }, []);

  useEffect(() => {
    if (services?.length > 0 && !selectedService) {
      const firstService = services[0];
      const serviceWithImage = {
        ...firstService,
        imageUrl: firstService?.blobs?.[0]?.url || '/images/default-service.jpg',
        specs: firstService.specs || [],
        description: firstService.description || '',
        notification: firstService.notification || '',
        status: firstService.status || 'On Sale',
        provider: firstService.provider || '',
        category: firstService.category || {},
        tags: firstService.tags || [],
        recommendation: firstService.recommendation || null,
        publishDate: firstService.publishDate || null
      };
      setSelectedService(serviceWithImage);
    }
  }, [services, selectedService]);

  useEffect(() => {
    console.log('services', services);
  }, [services]);

  const handleShowForm = () => {
    setShowForm(true);
  };

  const handleRowClick = (record) => {
    if (selectedService?.key !== record.key) {
      const serviceWithImage = {
        ...record,
        imageUrl: record?.blobs?.[0]?.url || '/images/default-service.jpg',
        specs: record.specs || [],
        description: record.description || '',
        notification: record.notification || '',
        status: record.status || 'On Sale',
        provider: record.provider || '',
        category: record.category || {},
        tags: record.tags || [],
        recommendation: record.recommendation || null,
        publishDate: record.publishDate || null
      };
      setSelectedService(serviceWithImage);
    }
  };

  const BreadcrumbNav = () => (
    <div className="flex flex-col space-y-2 mb-4 p-4">
      <div className="text-lg font-bold">{serviceFormConfig.service.title}</div>

      <div className="flex items-center text-sm">
        <Link to="/" className="text-green-600">
          <HomeOutlined className="mr-1" />
        </Link>
        <span className="mx-2 text-gray-500">/</span>
        <span
          className="text-green-600 cursor-pointer"
          onClick={() => window.location.href = '/service'}
        >
          {serviceFormConfig.service.title}
        </span>
        <span className="mx-2 text-gray-500">/</span>
        <span className="text-gray-500">{serviceFormConfig.service.add}</span>
      </div>
    </div>
  );




  return (
    <div className="flex flex-col items-center justify-start w-full h-screen overflow-auto">
      <ToolBar />
      <div className="flex flex-col w-full h-[calc(100vh-64px)]">

        <div className="flex-1 flex overflow-auto">
          {showForm ? (
            <>
              <div className="flex w-full space-x-4 overflow-auto">
                <div className="flex-1 overflow-hidden">
                  <BreadcrumbNav />
                  <Form formConfig={serviceFormConfig} formLogic={formLogic} useFormScroll={useFormScroll} title={serviceFormConfig.service.title} draftText={serviceFormConfig.service.draft} publishText={serviceFormConfig.service.publish} />
                </div>
                {/* <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
                  <Detail />
                </div> */}
              </div>
            </>
          ) : (
            <>
              <div className="flex w-full overflow-auto bg-gray-50">
                <div className="flex-1 flex flex-col shadow min-w-0 overflow-auto h-full p-4">
                  <div className="flex justify-between items-center mb-4">
                    <div className="text-lg font-bold">{serviceFormConfig.service.title}</div>
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={handleShowForm}
                      className="bg-[#ADDEAC] hover:bg-[#45a049] text-black hover:!text-black"
                    >
                      {serviceFormConfig.service.add}
                    </Button>
                  </div>
                  <Statistics total={totalServices} available={availableServices} lowStockWarnings={lowStockWarnings} onPromotion={onPromotion} drafts={drafts} totalTitle={statisticsConfig?.statistics?.service?.total?.title || "總服務數"} availableTitle={statisticsConfig?.statistics?.service?.available?.title || "上架服務數"} />
                  <Table
                    columns={columns({
                      setShowOrderManagement,
                      setSelectedService,
                      services,
                    })}
                    dataSource={services}
                    loading={loading}
                    pagination={{
                      pageSize: 10,
                      className: "sticky bottom-0 pb-2",
                    }}
                    onRow={(record) => ({
                      onClick: () => handleRowClick(record),
                      className: record.key === (selectedService?.key ?? '') ? "bg-gray-100" : "",
                    })}
                    rowClassName={(record) => `cursor-pointer ${record.key === (selectedService?.key ?? '') ? "bg-gray-100" : ""}`}
                    scroll={{ x: 2000 }}
                    size="small"
                    className="[&_.ant-table-thead>tr>th]:bg-[#E0F0E0]"
                  />
                </div>
                <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
                  <Detail
                    data={selectedService}
                    type="service"
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
