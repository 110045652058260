import { useState, useRef, useEffect } from 'react';
import { Button } from "antd";
import { Form as AntForm, Tabs, Row, Col } from 'antd';
import { useAmsContext } from '../context/AmsContext';

export default function Form({ formConfig, formLogic, useFormScroll, title, draftText, publishText }) {
  const formRef = useRef(null);
  const [form] = AntForm.useForm();
  const activeTab = useFormScroll(formRef);

  const { identityList } = useAmsContext();

  useEffect(() => {
    console.log('identityList', identityList);
  }, [identityList]);

  // Render form items
  const renderFormItems = (tabKey) => {
    return (
      <Row gutter={16}>
        {formConfig.formItems[tabKey].map(item => {
          const { name } = item;
          const fieldName = Array.isArray(name) ? name : [name];

          return (
            <Col span={item.col || 24} key={item.name}>
              <AntForm.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => {
                  // 比較 priceType 狀態是否改變
                  if (item.dependencies) {
                    return item.dependencies.some(dependency => {
                      const prev = prevValues[dependency];
                      const current = currentValues[dependency];
                      return prev !== current;
                    });
                  }
                  return false;
                }}
              >
                {() => {
                  const shouldHide = item.hidden && item.hidden({ getFieldValue: form.getFieldValue });
                  if (shouldHide) return null;

                  const formItemProps = {
                    name: item.name,
                    label: item.label,
                    rules: item.rules,
                    labelCol: item.labelCol,
                    wrapperCol: item.wrapperCol,
                    'data-tab': tabKey,
                  };

                  if (item.type === 'upload') {
                    formItemProps.valuePropName = 'fileList';
                    formItemProps.getValueFromEvent = e => e?.fileList;
                  }

                  const componentProps = {
                    ...item.options,
                    style: item.style
                  };

                  if (item.name[0] === 'basic' && item.name[1] === 'organization') {
                    componentProps.onChange = (value) => {
                      item.options.onChange(value);
                    };
                  }

                  return (
                    <AntForm.Item {...formItemProps}>
                      <item.component {...componentProps} />
                    </AntForm.Item>
                  );
                }}
              </AntForm.Item>
            </Col>
          );
        })}
      </Row>
    );
  };

  return (
    <div className="flex-1 flex flex-col shadow min-w-0 overflow-auto h-full">
      <div className="sticky top-0 z-50 bg-white">
        <div className="flex justify-between items-center p-4">
          <div className="text-lg font-bold">{title}</div>
          <div className="flex gap-3">
            <Button type="default" className="w-full sm:w-auto bg-[#ADDEAC] hover:bg-[#45a049] text-black hover:!text-black">
              {draftText}
            </Button>
            <Button
              type="primary"
              className="w-full sm:w-auto"
              onClick={() => form.submit()}
            >
              {publishText}
            </Button>
          </div>
        </div>
        <Tabs
          activeKey={activeTab}
          items={formConfig.tabs.map(tab => ({
            key: tab.key,
            label: tab.label
          }))}
          className="px-4 border-b service-tabs"
          type="line"
          onChange={(key) => formLogic.scrollToTab(key, formRef)}
        />
      </div>

      <div className="flex-1 p-4" ref={formRef}>
        <AntForm
          form={form}
          layout="vertical"
          onFinish={(values) => formLogic.handleSubmit(values)}
        >
          {formConfig.tabs.map(tab => (
            <div key={tab.key} id={tab.key} className="mb-8">
              <h3 className="text-xl font-bold mb-4">{tab.label}</h3>
              {renderFormItems(tab.key)}
            </div>
          ))}
        </AntForm>
      </div>
    </div>
  );
}
