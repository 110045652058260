import { request, callApi } from '@/utils';
import axios from 'axios';
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

// 直接獲取認證配置 電商
export const getAuthConfig = async () => {
  try {
    const [accessToken, serverUrl] = await Promise.all([
      getAccessToken(),
      getServerUrl()
    ]);

    return {
      accessToken,
      serverUrl
    };
  } catch (error) {
    console.error('獲取認證配置失敗:', error);
    throw new Error('Failed to fetch authentication configuration');
  }
};

// 直接獲取認證配置 官方
export const getOfficialAuthConfig = async () => {
  try {
    const [accessToken, serverUrl] = await Promise.all([
      getOfficialAccessToken(),
      getOfficialServerUrl()
    ]);

    return {
      accessToken,
      serverUrl
    };
  } catch (error) {
    console.error('獲取認證配置失敗:', error);
    throw new Error('Failed to fetch authentication configuration');
  }
};


// 獲取 Access Token
export const getAccessToken = async () => {
  try {
    const response = await request.get(`${SERVER_URL}/private/property/ecommerce/jwt`);
    return response.access_token;
  } catch (error) {
    console.error('獲取存取令牌失敗:', error);
    throw new Error('Unable to fetch access token');
  }
};

// 獲取 Access Token
export const getOfficialAccessToken = async () => {
  try {
    const response = await request.get(`${SERVER_URL}/private/property/official_site/jwt`);
    console.log('response', response.access_token);
    return response.access_token;
  } catch (error) {
    console.error('獲取存取令牌失敗:', error);
    throw new Error('Unable to fetch access token');
  }
};


// 獲取服務數據
export const fetchServicesAPI = async () => {
  try {
    // 1. 先獲取需要預約的分類
    const categoriesResponse = await callApi.get('/public/category/?need_booking=true');
    const categories = categoriesResponse.data;
    
    // 2. 獲取每個分類下的所有產品
    const servicesPromises = categories.map(category => 
      callApi.get(`/public/product/byCategory/${category.id}`)
    );
    
    const servicesResponses = await Promise.all(servicesPromises);
    
    // 3. 整合所有服務數據
    const allServices = servicesResponses.reduce((acc, response) => {
      const services = response.data.map(service => ({
        ...service,
        key: service.id,
        category: categories.find(cat => cat.id === service.category_id)
      }));
      return [...acc, ...services];
    }, []);

    return allServices;
    
  } catch (error) {
    console.error('獲取服務數據失敗:', error);
    throw new Error('Unable to fetch services');
  }
};

// 獲取服務數據
export const fetchProductsAPI = async (accessToken, serverUrl) => {
  try {
    const response = await request.get(`${serverUrl}/public/sp/`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    console.log(response);

    // 格式化數據，並返回
    return response.map((service) => ({
      ...service,
      key: service.id,
    }));
  } catch (error) {
    console.error('獲取服務數據失敗:', error);
    throw new Error('Unable to fetch services');
  }
};

// 獲取伺服器 URL
export const getServerUrl = async () => {
  try {
    const response = await request.get(`${SERVER_URL}/private/property/ecommerce/profile`);
    return response.body.server_url;
  } catch (error) {
    console.error('獲取伺服器 URL 失敗:', error);
    throw new Error('Unable to fetch server URL');
  }
};

export const getOfficialServerUrl = async () => {
  try {
    const response = await request.get(`${SERVER_URL}/private/property/official_site/profile`);
    console.log('response', response.body.server_url);
    
    return response.body.server_url;
  } catch (error) {
    console.error('獲取伺服器 URL 失敗:', error);
    throw new Error('Unable to fetch server URL');
  }
};

// 更新服務數據
export const updateServiceAPI = async (serviceId, updateData) => {
  try {
    const { name, description, notification } = updateData;
    const updates = [];
    
    // 更新名稱
    if (name) {
      updates.push(
        callApi.patch(
          `/private/sp/${serviceId}?fields=name&value=${encodeURIComponent(name)}`
        )
      );
    }

    // 更新描述
    if (description) {
      updates.push(
        callApi.patch(
          `/private/sp/${serviceId}?fields=description&value=${encodeURIComponent(description)}`
        )
      );
    }

    // 更新注意事項
    if (notification) {
      updates.push(
        callApi.patch(
          `/private/sp/${serviceId}?fields=notification&value=${encodeURIComponent(notification)}`
        )
      );
    }

    // 並行執行所有更新
    await Promise.all(updates);
    return true;
    
  } catch (error) {
    console.error('更新服務數據失敗:', error);
    throw new Error('Unable to update service');
  }
};

// 新增服務規格
export const addServiceSpec = async (accessToken, serverUrl, serviceId, specData) => {
  try {
    const response = await axios.post(
      `${serverUrl}/private/sp/${serviceId}/spec`,
      specData,
      {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('新增規格失敗:', error);
    throw error;
  }
};

// 獲取需求單數據
export const fetchOfficialProductsAPI = async () => {
  try {
    const response = await callApi.get('/group/6fbba4f2-d965-452a-8ec4-75e780317b25');
    console.log('response:', response);
    return response;
  } catch (error) {
    console.error('獲取需求單數據失敗:', error);
    throw new Error('Unable to fetch demand');
  }
};