import { Result, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

export default function NotFound() {

    const { t } = useTranslation();
    
    const handleGoHome = useCallback(() => {
        window.location.href = '/explore';
    }, []);
    
    const handleGoBack = useCallback(() => {
        window.history.go(-1);
    }, []);

    return (
        <div className="flex items-center justify-center min-h-screen w-full">
            <Result
                status="404"
                title={t('404.title')}
                subTitle={t('404.subtitle')}
                extra={[
                    <Button 
                        type="primary" 
                        key="console" 
                        onClick={handleGoHome}
                    >
                        {t('404.backHome')}
                    </Button>,
                    <Button 
                        key="buy" 
                        onClick={handleGoBack}
                    >
                        {t('404.backPrev')}
                    </Button>
                ]}
            />
        </div>
    );
}