import React, { useState, useEffect } from "react";
import { Button, Select, Input, Upload, Spin, message } from "antd";
import { PlusOutlined, CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import "tailwindcss/tailwind.css";
import { HomeOutlined } from '@ant-design/icons';
import { Link, useNavigate } from "react-router-dom";
import Preview from "@/app/cms/components/Official/Preview";
import axios from 'axios';
import { useAuth } from "@/contexts/AuthProvider";
import { getOfficialServerUrl, getOfficialAccessToken } from '@/app/cms/services/api';

const PROXY_URL = process.env.REACT_APP_SERVER_URL + '/private/property/official_site/proxy';
const API_KEY = process.env.REACT_APP_API_KEY;

// 定義群組常數
const GROUPS = {
    HEADER: 'header',
    MAIN: 'main',
    FOOTER: 'footer'
};


const Management = ({ onSave }) => {
    const { token } = useAuth();
    const [loading, setLoading] = useState(true);
    const [logo, setLogo] = useState(null);
    const [companyName, setCompanyName] = useState("");
    const [bannerImages, setBannerImages] = useState([]);
    const [mobileContentImages, setMobileContentImages] = useState([]);
    const [partners, setPartners] = useState([]);
    const [platforms, setPlatforms] = useState([]);
    const [desktopContentImages, setDesktopContentImages] = useState([]);
    const [videoUrl, setVideoUrl] = useState("");
    const [serviceDescription, setServiceDescription] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [groupsData, setGroupsData] = useState({
        header: null,
        main: null,
        footer: null
    });
    const [itemsData, setItemsData] = useState({
        header: {
            companyNameItem: null,
            logoItem: null
        },
        main: {
            bannerItems: [],
            mobileItems: [],
            desktopItems: [],
            contentInfoItem: null
        },
        footer: {
            footerInfoItem: null
        }
    });
    const navigate = useNavigate();
    const callApi = async (url, method = 'GET', body = {}, query = {}, isFileUpload = false) => {
        try {
            // 等待獲取 serverUrl 和 accessToken
            const [serverUrl, accessToken] = await Promise.all([
                getOfficialServerUrl(),
                getOfficialAccessToken()
            ]);

            // 檢查是否成功獲取到值
            if (!serverUrl || !accessToken) {
                throw new Error('無法獲取伺服器資訊或存取權杖');
            }

            const headers = {
                'x-api-key': API_KEY,
                'Authorization': `Bearer ${accessToken}`
            };

            // 如果不是檔案上傳，添加 Content-Type
            if (!isFileUpload) {
                headers['Content-Type'] = 'application/json';
            }

            // 構建請求配置
            const config = {
                headers,
                params: query  // 將 query 參數添加到 URL
            };

            let response;
            const fullUrl = serverUrl + url;

            // 根據不同的 HTTP 方法發送請求
            switch (method.toUpperCase()) {
                case 'GET':
                    response = await axios.get(fullUrl, config);
                    break;
                case 'POST':
                    response = await axios.post(fullUrl, body, config);
                    break;
                case 'PUT':
                    response = await axios.put(fullUrl, body, config);
                    break;
                case 'PATCH':
                    response = await axios.patch(fullUrl, body, config);
                    break;
                case 'DELETE':
                    response = await axios.delete(fullUrl, config);
                    break;
                default:
                    throw new Error(`不支援的 HTTP 方法: ${method}`);
            }

            return response.data;
        } catch (error) {
            console.error('API 請求失敗:', error);
            throw error;
        }
    };

    const callApiItem = async (itemId, file, method = 'POST', body = {}, url) => {
        try {
            // 等待獲取 serverUrl 和 accessToken
            const [serverUrl, accessToken] = await Promise.all([
                getOfficialServerUrl(),
                getOfficialAccessToken()
            ]);

            // 檢查是否成功獲取到值
            if (!serverUrl || !accessToken) {
                throw new Error('無法獲取伺服器資訊或存取權杖');
            }

            const headers = {
                'x-api-key': API_KEY,
                'Authorization': `Bearer ${accessToken}`
            };

            const formData = new FormData();

            // 如果 file 是 base64 字串，需要先轉換成 Blob
            let fileToUpload = file;
            if (typeof file === 'string' && file.startsWith('data:')) {
                const response = await fetch(file);
                fileToUpload = await response.blob();
            }
            formData.append('file', fileToUpload);

            // 構建請求配置
            const config = {
                headers,
                params: {
                    item_id: itemId
                }
            };

            let response;
            const fullUrl = serverUrl + url;

            // 根據不同的 HTTP 方法發送請求
            switch (method.toUpperCase()) {
                case 'GET':
                    response = await axios.get(fullUrl, config);
                    break;
                case 'POST':
                    response = await axios.post(fullUrl, body, config);
                    break;
                case 'PUT':
                    response = await axios.put(fullUrl, body, config);
                    break;
                case 'PATCH':
                    response = await axios.patch(fullUrl, body, config);
                    break;
                case 'DELETE':
                    response = await axios.delete(fullUrl, config);
                    break;
                default:
                    throw new Error(`不支援的 HTTP 方法: ${method}`);
            }

            return response.data;
            // const response = await axios.post(
            //     `${serverUrl}/add_blob_to_item`,
            //     formData,
            //     {
            //         headers,
            //         params: {
            //             item_id: itemId
            //         }
            //     }
            // );
            // return response.data;
        } catch (error) {
            console.error('檔案上傳失敗:', error);
            throw error;
        }
    };

    // BreadcrumbNav Component
    const BreadcrumbNav = () => (
        <div className="flex flex-col space-y-2 mb-4 p-4">
            <div className="text-lg font-bold">後台管理</div>

            <div className="flex items-center text-sm">
                <Link to="/" className="text-green-600">
                    <HomeOutlined className="mr-1" />
                </Link>
                <span className="mx-2 text-gray-500">/</span>
                <span
                    className="text-gray-500"
                >
                    後台管理
                </span>
            </div>
        </div>
    );

    // 創建群組的輔助函數
    const createGroup = async (name) => {
        try {
            const response = await callApi(
                '/group/',
                'POST',
                {},
                {
                    name,
                    description: `${name} section of the website`
                }
            );
            return response;
        } catch (error) {
            console.error(`創建 ${name} 群組失敗:`, error);
            throw error;
        }
    };

    // 定義 main group 的 item 類型
    const MAIN_ITEMS = {
        BANNER: 'banner',
        MOBILE: 'mobile_content',
        VIDEO: 'video_content',
        PARTNERS: 'partners_info'
    };

    // 初始化頁面資料
    useEffect(() => {
        const initializeData = async () => {
            try {
                setLoading(true);
                // 1. 先獲取所有群組列表
                const groupsResponse = await callApi('/group/', 'GET', {}, {});

                // 檢查是否有錯誤回應
                if (groupsResponse.detail === "Access denied (Wrong API Key)") {
                    message.error('尚未開通此功能', 3);
                    navigate('/');
                    return;
                }

                // 檢查必要的群組是否存在
                const existingGroups = {};
                groupsResponse.forEach(group => {
                    if (Object.values(GROUPS).includes(group.name)) {
                        existingGroups[group.name] = group;
                    }
                });

                // 設置群組資料
                setGroupsData(existingGroups);

                // 2. 獲取每個群組的詳細資料
                for (const [groupName, group] of Object.entries(existingGroups)) {
                    try {
                        const groupDetail = await callApi(`/group/${group.id}`, 'GET', {}, {});

                        switch (groupName) {
                            case GROUPS.HEADER:
                                if (groupDetail.items && groupDetail.items.length > 0) {
                                    // 找到最新的 company_info item
                                    const companyInfoItem = groupDetail.items
                                        .filter(item => item.name === 'company_info')
                                        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))[0];

                                    if (companyInfoItem) {
                                        if (companyInfoItem.information?.companyName) {
                                            setCompanyName(companyInfoItem.information.companyName);
                                        }

                                        // 設置 logo URL（如果存在）
                                        if (companyInfoItem.blobs && companyInfoItem.blobs.length > 0) {
                                            const logoBlob = companyInfoItem.blobs[0]; // 取最新的一張圖片
                                            setLogo(logoBlob.url);
                                        }

                                        setItemsData(prev => ({
                                            ...prev,
                                            header: {
                                                companyNameItem: companyInfoItem,
                                                logoItem: companyInfoItem
                                            }
                                        }));
                                    }
                                }
                                break;

                            case GROUPS.MAIN:
                                if (groupDetail.items && groupDetail.items.length > 0) {
                                    // 按類型分類並只取最新的一個
                                    const itemsByType = {};
                                    groupDetail.items.forEach(item => {
                                        if (!itemsByType[item.name] ||
                                            new Date(item.created_at) > new Date(itemsByType[item.name].created_at)) {
                                            itemsByType[item.name] = item;
                                        }
                                    });

                                    // 設置 Banner 圖片
                                    if (itemsByType[MAIN_ITEMS.BANNER]) {
                                        const bannerUrls = itemsByType[MAIN_ITEMS.BANNER].blobs?.map(blob => blob.url) || [];
                                        setBannerImages(bannerUrls);
                                    }

                                    // 設置 Mobile Content 圖片
                                    if (itemsByType[MAIN_ITEMS.MOBILE]) {
                                        const mobileUrls = itemsByType[MAIN_ITEMS.MOBILE].blobs?.map(blob => blob.url) || [];
                                        setMobileContentImages(mobileUrls);
                                    }

                                    // 設置 Video Content URL
                                    if (itemsByType[MAIN_ITEMS.VIDEO] && itemsByType[MAIN_ITEMS.VIDEO].information?.videoUrl) {
                                        setVideoUrl(itemsByType[MAIN_ITEMS.VIDEO].information.videoUrl);
                                    }

                                    // 設置 Partners Info
                                    if (itemsByType[MAIN_ITEMS.PARTNERS] && itemsByType[MAIN_ITEMS.PARTNERS].information?.partners) {
                                        setPartners(itemsByType[MAIN_ITEMS.PARTNERS].information.partners);
                                    }

                                    setItemsData(prev => ({
                                        ...prev,
                                        main: {
                                            bannerItem: itemsByType[MAIN_ITEMS.BANNER] || null,
                                            mobileItem: itemsByType[MAIN_ITEMS.MOBILE] || null,
                                            videoItem: itemsByType[MAIN_ITEMS.VIDEO] || null,
                                            partnersItem: itemsByType[MAIN_ITEMS.PARTNERS] || null
                                        }
                                    }));
                                }
                                break;

                            case GROUPS.FOOTER:
                                if (groupDetail.items && groupDetail.items.length > 0) {
                                    const info = groupDetail.items.find(item =>
                                        item.information?.serviceDescription || item.information?.platforms
                                    );
                                    if (info) {
                                        if (info.information.serviceDescription) {
                                            setServiceDescription(info.information.serviceDescription);
                                        }
                                        if (info.information.platforms) {
                                            setPlatforms(info.information.platforms);
                                        }
                                        setItemsData(prev => ({
                                            ...prev,
                                            footer: {
                                                footerInfoItem: info
                                            }
                                        }));
                                    }
                                }
                                break;
                        }
                    } catch (error) {
                        console.error(`獲取群組 ${groupName} 詳細資料失敗:`, error);
                    }
                }
            } catch (error) {
                console.error('初始化失敗:', error);
                // 檢查錯誤是否為 API Key 錯誤
                if (error.response?.data?.detail === "Access denied (Wrong API Key)") {
                    message.error('尚未開通此功能', 3);
                    navigate('/');
                    return;
                }
            } finally {
                setLoading(false);
            }
        };

        initializeData();
    }, [navigate]);

    const createOrUpdateGroup = async (groupName, data = {}) => {
        try {
            // 檢查群組是否存在
            const existingGroup = await callApi(
                '/group/byName',
                'GET',
                {},
                { name: groupName }
            );

            if (existingGroup) {
                return existingGroup.id;
            } else {
                // 建立新群組
                const response = await callApi(
                    '/group/',
                    'POST',
                    {},
                    {
                        name: groupName,
                        description: `${groupName} section of the website`
                    }
                );
                return response.id;
            }
        } catch (error) {
            console.error(`群組操作失敗: ${error.message}`);
            throw error;
        }
    };

    const uploadFile = async (file, itemId) => {
        try {
            await callApiItem(itemId, file, 'POST', {}, '/add_blob_to_item');
        } catch (error) {
            throw new Error(`檔案上傳失敗: ${error.message}`);
        }
    };

    const handleSave = async () => {
        setIsSubmitting(true);

        try {
            // 1. 檢查並更新 Header Group
            let headerGroup = groupsData[GROUPS.HEADER];
            if (!headerGroup) {
                headerGroup = await callApi(
                    '/group/',
                    'POST',
                    {},
                    {
                        name: GROUPS.HEADER,
                        description: 'Header section of the website'
                    }
                );
            }

            // 檢查是否有現有的 Header Item
            if (itemsData.header.companyNameItem) {
                // 更新現有項目
                await callApi(
                    `/item/${itemsData.header.companyNameItem.id}/information`,
                    'PATCH',
                    {
                        companyName: companyName
                    }
                );

                // 如果有新的 logo 要上傳
                if (logo && (logo instanceof File || logo.startsWith('data:'))) {
                    // 先刪除所有現有的 blobs
                    if (itemsData.header.companyNameItem.blobs?.length > 0) {
                        const deletePromises = itemsData.header.companyNameItem.blobs.map(blob =>
                            callApi(
                                `/item/${itemsData.header.companyNameItem.id}/blobs/${blob.id}`,
                                'DELETE'
                            )
                        );
                        await Promise.all(deletePromises);
                    }

                    // 上傳新的 logo
                    await uploadFile(logo, itemsData.header.companyNameItem.id);
                }
            } else {
                // 創建新項目
                const headerItem = await callApi(
                    `/group/${headerGroup.id}`,
                    'POST',
                    {
                        name: 'company_info',
                        description: 'Company information',
                        information: {
                            companyName: companyName
                        }
                    }
                );

                // 如果有 logo 要上傳
                if (logo && (logo instanceof File || logo.startsWith('data:'))) {
                    await uploadFile(logo, headerItem.id);
                }

                itemsData.header.companyNameItem = headerItem;
            }

            // 2. 查並更新 Main Group
            let mainGroup = groupsData[GROUPS.MAIN];
            if (!mainGroup) {
                mainGroup = await callApi(
                    '/group/',
                    'POST',
                    {},
                    {
                        name: GROUPS.MAIN,
                        description: 'Main section of the website'
                    }
                );
            }

            // Banner Images
            if (itemsData.main.bannerItem) {
                // 獲取現有的 blobs
                const existingBlobs = itemsData.main.bannerItem.blobs || [];

                // 如果目前沒有任何圖片，但有現有的 blobs，則全部刪除
                if (bannerImages.length === 0 && existingBlobs.length > 0) {
                    const deletePromises = existingBlobs.map(blob =>
                        callApi(
                            `/item/${itemsData.main.bannerItem.id}/blobs/${blob.id}`,
                            'DELETE'
                        )
                    );
                    await Promise.all(deletePromises);
                }
                // 如果有圖片，則進行正常的比對和更新
                else if (bannerImages.length > 0) {
                    const existingUrls = existingBlobs.map(blob => blob.url);
                    const newImages = bannerImages.filter(img => !existingUrls.includes(img));
                    const deletedBlobs = existingBlobs.filter(blob => !bannerImages.includes(blob.url));

                    // 刪除不需要的 blobs
                    if (deletedBlobs.length > 0) {
                        const deletePromises = deletedBlobs.map(blob =>
                            callApi(
                                `/item/${itemsData.main.bannerItem.id}/blobs/${blob.id}`,
                                'DELETE'
                            )
                        );
                        await Promise.all(deletePromises);
                    }

                    // 上傳新圖片
                    for (const image of newImages) {
                        if (image instanceof File || image.startsWith('data:')) {
                            await uploadFile(image, itemsData.main.bannerItem.id);
                        }
                    }
                }
            } else if (bannerImages.length > 0) {
                // 創建新的 Banner Item
                const bannerItem = await callApi(
                    `/group/${mainGroup.id}`,
                    'POST',
                    {
                        name: MAIN_ITEMS.BANNER,
                        description: 'Banner images'
                    }
                );

                // 上所有圖片
                for (const image of bannerImages) {
                    if (image instanceof File || image.startsWith('data:')) {
                        await uploadFile(image, bannerItem.id);
                    }
                }
                itemsData.main.bannerItem = bannerItem;
            }

            // Mobile Content Images
            if (itemsData.main.mobileItem) {
                // 獲取現有的 blobs
                const existingBlobs = itemsData.main.mobileItem.blobs || [];

                // 如果目前沒有任何圖片，但有現有的 blobs，則全部刪除
                if (mobileContentImages.length === 0 && existingBlobs.length > 0) {
                    const deletePromises = existingBlobs.map(blob =>
                        callApi(
                            `/item/${itemsData.main.mobileItem.id}/blobs/${blob.id}`,
                            'DELETE'
                        )
                    );
                    await Promise.all(deletePromises);
                }
                // 如果有圖片，則進行正常的比對和更新
                else if (mobileContentImages.length > 0) {
                    const existingUrls = existingBlobs.map(blob => blob.url);
                    const newImages = mobileContentImages.filter(img => !existingUrls.includes(img));
                    const deletedBlobs = existingBlobs.filter(blob => !mobileContentImages.includes(blob.url));

                    // 刪除不需要的 blobs
                    if (deletedBlobs.length > 0) {
                        const deletePromises = deletedBlobs.map(blob =>
                            callApi(
                                `/item/${itemsData.main.mobileItem.id}/blobs/${blob.id}`,
                                'DELETE'
                            )
                        );
                        await Promise.all(deletePromises);
                    }

                    // 上傳新圖片
                    for (const image of newImages) {
                        if (image instanceof File || image.startsWith('data:')) {
                            await uploadFile(image, itemsData.main.mobileItem.id);
                        }
                    }
                }
            } else if (mobileContentImages.length > 0) {
                // 創建新的 Mobile Content Item
                const mobileItem = await callApi(
                    `/group/${mainGroup.id}`,
                    'POST',
                    {
                        name: MAIN_ITEMS.MOBILE,
                        description: 'Mobile content images'
                    }
                );

                // 上傳所有圖片
                for (const image of mobileContentImages) {
                    if (image instanceof File || image.startsWith('data:')) {
                        await uploadFile(image, mobileItem.id);
                    }
                }
                itemsData.main.mobileItem = mobileItem;
            }

            // Video Content
            if (videoUrl) {
                if (itemsData.main.videoItem) {
                    // 更新現有 Video Item
                    await callApi(
                        `/item/${itemsData.main.videoItem.id}/information`,
                        'PATCH',
                        {
                            videoUrl
                        }
                    );
                } else {
                    // 創建新的 Video Item
                    const videoItem = await callApi(
                        `/group/${mainGroup.id}`,
                        'POST',
                        {
                            name: MAIN_ITEMS.VIDEO,
                            description: 'Video content',
                            information: { videoUrl }
                        }
                    );
                    itemsData.main.videoItem = videoItem;
                }
            }

            // Partners Info
            if (partners.length > 0) {
                if (itemsData.main.partnersItem) {
                    // 更新現有 Partners Item
                    await callApi(
                        `/item/${itemsData.main.partnersItem.id}/information`,
                        'PATCH',
                        {
                            partners
                        }
                    );
                } else {
                    // 創建新的 Partners Item
                    const partnersItem = await callApi(
                        `/group/${mainGroup.id}`,
                        'POST',
                        {
                            name: MAIN_ITEMS.PARTNERS,
                            description: 'Partners information',
                            information: { partners }
                        }
                    );
                    itemsData.main.partnersItem = partnersItem;
                }
            } else if (itemsData.main.partnersItem) {
                // 如果合作廠商從1個變成0個，刪除現有的 Partners Item
                await callApi(
                    `/item/${itemsData.main.partnersItem.id}`,
                    'DELETE'
                );
                itemsData.main.partnersItem = null;
            }

            // 3. 檢查並更新 Footer Group
            let footerGroup = groupsData[GROUPS.FOOTER];
            if (!footerGroup) {
                footerGroup = await callApi(
                    '/group/',
                    'POST',
                    {},
                    {
                        name: GROUPS.FOOTER,
                        description: 'Footer section of the website'
                    }
                );
            }

            // Footer Information
            if (serviceDescription || platforms.length > 0) {
                if (itemsData.footer.footerInfoItem) {
                    // 更新現有 Footer Info Item
                    await callApi(
                        `/item/${itemsData.footer.footerInfoItem.id}/information`,
                        'PATCH',
                        {
                            serviceDescription,
                            platforms
                        }
                    );
                } else {
                    // 創建新的 Footer Info Item
                    const footerItem = await callApi(
                        `/group/${footerGroup.id}`,
                        'POST',
                        {
                            name: 'footer_info',
                            description: 'Footer information',
                            information: {
                                serviceDescription,
                                platforms
                            }
                        }
                    );
                    itemsData.footer.footerInfoItem = footerItem;
                }
            }

            message.success('內容已成功發布！');
            onSave();

        } catch (error) {
            console.error('發布失敗:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    // 處理檔案選擇
    const handleFileChange = (file, type) => {
        if (file && type === 'logo') {
            setLogo(file);
        }
    };

    // get social links
    const getSocialLinks = (platforms) => {
        const socialLinks = {};
        platforms.forEach(platform => {
            const type = platform.type.toLowerCase();
            if (type === 'facebook' || type === 'instagram' || type === 'youtube' || type === 'tiktok') {
                socialLinks[type] = platform.url;
            }
        });
        return socialLinks;
    };

    if (loading || isSubmitting) {
        return (
            <div className="flex justify-center items-center w-full h-screen">
                <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
            </div>
        );
    }

    // 表輸入處理函數 - 只更新本地 state
    const handleCompanyNameChange = (value) => {
        setCompanyName(value);
    };

    const handleVideoUrlChange = (value) => {
        setVideoUrl(value);
    };

    const handleServiceDescriptionChange = (value) => {
        setServiceDescription(value);
    };

    // 處理圖片更新
    const handleUpdateImage = (image, type, index) => {
        if (type === 'logo') {
            // 如果是從裁剪器來的 base64 圖片，直接設置
            if (typeof image === 'string' && image.startsWith('data:')) {
                setLogo(image);
            } else {
                // 如果是點擊觸發的上傳，才開啟檔案選擇
                const input = document.createElement('input');
                input.type = 'file';
                input.accept = 'image/*';
                input.onchange = (e) => {
                    const file = e.target.files[0];
                    if (file) {
                        const reader = new FileReader();
                        reader.onload = () => {
                            setLogo(reader.result);
                        };
                        reader.readAsDataURL(file);
                    }
                };
                input.click();
            }
        } else if (type === 'banner') {
            // 處理 banner 片更新邏輯
            if (typeof image === 'string' && image.startsWith('data:')) {
                const newBannerImages = [...bannerImages];
                newBannerImages[index] = image;
                setBannerImages(newBannerImages);
            } else {
                // 原有的上傳邏輯
            }
        } else if (type === 'mobile') {
            // 處理 mobile 圖片更新邏輯
            if (typeof image === 'string' && image.startsWith('data:')) {
                const newMobileImages = [...mobileContentImages];
                newMobileImages[index] = image;
                setMobileContentImages(newMobileImages);
            } else {
                // 原有的上傳邏輯
            }
        }
    };

    return (
        <div className="flex w-full overflow-auto">
            {/* Left Form Area */}
            <div className="flex-1 flex flex-col shadow min-w-0 overflow-auto h-full">
                <BreadcrumbNav />
                <div className="bg-white rounded-lg mb-6">
                    {/* Header */}
                    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-6 p-4 shadow-lg">
                        <h1 className="text-[20px] font-semibold">編輯首頁</h1>
                        <div className="flex gap-3">
                            <Button
                                type="primary"
                                className="w-[161px] h-[36px] px-4 py-[17px] gap-[10px] rounded-xl bg-[#67BE5F] text-white"
                                onClick={handleSave}
                            >
                                發布
                            </Button>
                        </div>
                    </div>

                    {/* Logo & Company Name */}
                    <div className="my-4 shadow-lg rounded-lg p-6 border border-[#F0F0F0]">
                        <h1 className="text-[20px] font-semibold mb-4">頁首</h1>
                        <div className="flex justify-end">
                            <div className="flex justify-start gap-4 space-x-4 w-[95%]">
                                <Upload
                                    listType="picture-card"
                                    className="w-[60px] h-[60px] [&_.ant-upload]:!w-[74px] [&_.ant-upload]:!h-[74px]"
                                    showUploadList={false}
                                    accept="image/*"
                                    beforeUpload={(file) => {
                                        const reader = new FileReader();
                                        reader.onload = () => {
                                            setLogo(reader.result);
                                        };
                                        reader.readAsDataURL(file);
                                        return false;
                                    }}
                                >
                                    {logo ? (
                                        <img
                                            src={logo}
                                            alt="Logo"
                                            className="w-full h-full object-contain"
                                        />
                                    ) : (
                                        <div className="flex flex-col items-center justify-center">
                                            <PlusOutlined className="text-[#B9B9B9] text-sm" />
                                            <div className="mt-1 text-[#B9B9B9] text-xs">廠商logo</div>
                                        </div>
                                    )}
                                </Upload>
                                <div className="flex-1">
                                    <div className="text-sm mb-1">廠商名稱</div>
                                    <Input
                                        placeholder="請輸入廠商名稱"
                                        value={companyName}
                                        onChange={(e) => handleCompanyNameChange(e.target.value)}
                                        className="w-1/5"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="my-4 shadow-lg rounded-lg p-6 border border-[#F0F0F0]">
                        <h1 className="text-[20px] font-semibold mb-4">主要內容</h1>
                        {/* Banner Upload */}
                        <div className="my-4 rounded p-4">
                            <h2 className="text-[20px] font-bold mb-4">Banner 圖片/影片</h2>
                            <div className="flex flex-wrap gap-4 justify-start">
                                {/* preview image */}
                                {bannerImages.map((img, index) => (
                                    <div key={index} className="relative w-[360px] h-[202.5px]">
                                        <img
                                            src={img}
                                            alt={`Banner ${index + 1}`}
                                            className="w-full h-full object-cover rounded-lg cursor-pointer"
                                            onClick={() => handleUpdateImage(img, "banner", index)}
                                        />
                                        <button
                                            className="absolute top-2 right-2 bg-gray-500 text-white rounded-full p-1 w-6 h-6 flex items-center justify-center"
                                            onClick={() => {
                                                const updated = [...bannerImages];
                                                updated.splice(index, 1);
                                                setBannerImages(updated);
                                            }}
                                        >
                                            &times;
                                        </button>
                                    </div>
                                ))}

                                {/* upload button - only show when the number of images is less than 5 */}
                                {bannerImages.length < 5 && (
                                    <Upload
                                        listType="picture-card"
                                        className="[&_.ant-upload]:!w-[360px] [&_.ant-upload]:!h-[202.5px]"
                                        showUploadList={false}
                                        multiple={false}
                                        accept="image/*"
                                        beforeUpload={(file) => {
                                            if (bannerImages.length >= 5) {
                                                return false;
                                            }
                                            const reader = new FileReader();
                                            reader.onload = () => {
                                                setBannerImages((prev) => [...prev, reader.result]);
                                            };
                                            reader.readAsDataURL(file);
                                            return false;
                                        }}
                                    >
                                        <div className="w-[360px] h-[202.5px]">
                                            <img
                                                src="/images/upload-btn.png"
                                                alt="上傳按鈕"
                                                width={360}
                                                height={202.5}
                                                priority
                                                sizes="100vw"
                                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                            />
                                        </div>
                                    </Upload>
                                )}
                            </div>
                        </div>

                        {/* Mobile Content Upload */}
                        <div className="my-4 rounded p-4">
                            <h2 className="text-[20px] font-bold mb-4">內容圖片(手機版)</h2>
                            <div className="flex flex-wrap gap-4 justify-start">
                                <Upload
                                    listType="picture-card"
                                    className="[&_.ant-upload]:!w-[340px] [&_.ant-upload]:!h-[190px]"
                                    showUploadList={false}
                                    multiple
                                    accept="image/*"
                                    beforeUpload={(file) => {
                                        const reader = new FileReader();
                                        reader.onload = () => {
                                            setMobileContentImages((prev) => [...prev, reader.result]);
                                        };
                                        reader.readAsDataURL(file);
                                        return false;
                                    }}
                                >
                                    <div className="w-[340px] h-[190px]">
                                        <img
                                            src="/images/upload-btn1.png"
                                            alt="上傳按鈕"
                                            width={340}
                                            height={190}
                                            priority
                                            sizes="100vw"
                                            style={{ width: '100%', height: '100%' }}
                                        />
                                    </div>
                                </Upload>
                                {/* show uploaded mobile content images */}
                                <div className="flex flex-wrap gap-4 mt-4">
                                    {mobileContentImages.map((img, index) => (
                                        <div key={index} className="relative w-[360px] h-[202.5px]">
                                            <img
                                                src={img}
                                                alt={`Mobile Content ${index + 1}`}
                                                className="w-full h-full object-cover rounded-lg cursor-pointer"
                                                onClick={() => handleUpdateImage(img, "mobile", index)}
                                            />
                                            <button
                                                className="absolute top-2 right-2 bg-gray-500 text-white rounded-full p-1 w-6 h-6 flex items-center justify-center"
                                                onClick={() => {
                                                    const updated = [...mobileContentImages];
                                                    updated.splice(index, 1);
                                                    setMobileContentImages(updated);
                                                }}
                                            >
                                                &times;
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        {/* Desktop Content Upload */}
                        {/* <div className="my-4 rounded p-4">
                            <h2 className="text-[20px] font-bold mb-4">內容圖片(桌機版)</h2>
                            <div className="flex flex-wrap gap-4 justify-start">
                                <Upload
                                    listType="picture-card"
                                    className="[&_.ant-upload]:!w-[340px] [&_.ant-upload]:!h-[190px]"
                                    showUploadList={false}
                                    multiple
                                    accept="image/*"
                                    beforeUpload={(file) => {
                                        const reader = new FileReader();
                                        reader.onload = () => {
                                            setDesktopContentImages((prev) => [...prev, reader.result]);
                                        };
                                        reader.readAsDataURL(file);
                                        return false;
                                    }}
                                >
                                    <div className="w-[340px] h-[190px]">
                                        <img
                                            src="/images/upload-btn1.png"
                                            alt="上傳按鈕"
                                            width={340}
                                            height={190}
                                            priority
                                            sizes="100vw"
                                            style={{ width: '100%', height: '100%' }}
                                        />
                                    </div>
                                </Upload>
                                <div className="flex flex-wrap gap-4 mt-4">
                                    {desktopContentImages.map((img, index) => (
                                        <div key={index} className="relative">
                                            <img
                                                src={img}
                                                alt={`Desktop Content ${index + 1}`}
                                                className="w-32 h-32 object-contain rounded-lg cursor-pointer"
                                                onClick={() => handleUpdateImage(img, "desktop", index)}
                                            />
                                            <button
                                                className="absolute top-2 right-2 bg-gray-500 text-white rounded-full p-1 w-6 h-6 flex items-center justify-center"
                                                onClick={() => {
                                                    const updated = [...desktopContentImages];
                                                    updated.splice(index, 1);
                                                    setDesktopContentImages(updated);
                                                }}
                                            >
                                                &times;
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div> */}

                        {/* Video Upload */}
                        <div className="my-4 rounded p-4">
                            <h2 className="text-[20px] font-bold mb-4">插入影���</h2>
                            <div className="flex flex-wrap gap-4 justify-start">
                                <h4>影片連結網址（以Youtube連結為主，私人及非公開影片將無法正常播放）</h4>
                                <Input
                                    placeholder="請輸入影片連結網址"
                                    value={videoUrl}
                                    onChange={(e) => handleVideoUrlChange(e.target.value)}
                                />
                            </div>
                        </div>

                        {/* Partners Section */}
                        <div className="my-4 rounded p-4">
                            <h2 className="text-[20px] font-bold mb-4">合作廠商</h2>
                            <div className="bg-[#F7F7F7] p-4 rounded-lg">
                                {partners.map((partner, index) => (
                                    <div className="flex items-start gap-6 mb-6" key={partner.id}>
                                        <div className="flex-shrink-0">
                                            <Upload
                                                listType="picture-card"
                                                className="w-[74px] h-[74px] [&_.ant-upload]:!w-[74px] [&_.ant-upload]:!h-[74px]"
                                                showUploadList={false}
                                                accept="image/*"
                                                beforeUpload={(file) => {
                                                    const reader = new FileReader();
                                                    reader.onload = () => {
                                                        const updatedPartners = [...partners];
                                                        updatedPartners[index].logo = reader.result;
                                                        setPartners(updatedPartners);
                                                    };
                                                    reader.readAsDataURL(file);
                                                    return false;
                                                }}
                                            >
                                                {partner.logo ? (
                                                    <img src={partner.logo} alt="Partner Logo" className="w-full h-full object-contain" />
                                                ) : (
                                                    <div className="flex flex-col items-center justify-center">
                                                        <PlusOutlined className="text-[#B9B9B9]" />
                                                        <div className="mt-2 text-[#B9B9B9] text-xs">廠商logo</div>
                                                    </div>
                                                )}
                                            </Upload>
                                        </div>
                                        <div className="flex-grow grid grid-cols-3 gap-4">
                                            <div>
                                                <div className="text-sm mb-1">廠商名稱</div>
                                                <Input
                                                    placeholder="廠商名稱"
                                                    value={partner.name}
                                                    onChange={(e) => {
                                                        const updatedPartners = [...partners];
                                                        updatedPartners[index].name = e.target.value;
                                                        setPartners(updatedPartners);
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <div className="text-sm mb-1">類別</div>
                                                <Input
                                                    placeholder="類別"
                                                    value={partner.category}
                                                    onChange={(e) => {
                                                        const updatedPartners = [...partners];
                                                        updatedPartners[index].category = e.target.value;
                                                        setPartners(updatedPartners);
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <div className="text-sm mb-1">連結網址</div>
                                                <Input
                                                    placeholder="連結網址"
                                                    value={partner.url}
                                                    onChange={(e) => {
                                                        const updatedPartners = [...partners];
                                                        updatedPartners[index].url = e.target.value;
                                                        setPartners(updatedPartners);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <Button
                                            type="text"
                                            danger
                                            icon={<CloseOutlined />}
                                            className="flex-shrink-0"
                                            onClick={() => {
                                                const updatedPartners = partners.filter((_, i) => i !== index);
                                                setPartners(updatedPartners);
                                            }}
                                        />
                                    </div>
                                ))}
                                <Button
                                    type="dashed"
                                    className="mt-4 text-[#67BE5F] border-[#67BE5F]"
                                    icon={<PlusOutlined />}
                                    onClick={() => {
                                        setPartners([...partners, { id: Date.now().toString(), logo: "", name: "", category: "", url: "" }]);
                                    }}
                                >
                                    新增合作廠商
                                </Button>
                            </div>
                        </div>

                    </div>

                    {/* Platforms Section */}
                    <div className="my-4 shadow-lg rounded p-4 border border-[#F0F0F0]">
                        <h1 className="text-[20px] font-semibold mb-4">頁尾</h1>

                        <div className="my-4 rounded-lg p-6 ">
                            <div className="mb-4">
                                <h4 className="font-['Inter'] text-[14px] font-normal leading-[18.2px] text-left mb-2">服務簡介</h4>
                                <Input.TextArea
                                    placeholder="請輸入服務介紹"
                                    value={serviceDescription}
                                    onChange={(e) => handleServiceDescriptionChange(e.target.value)}
                                />
                            </div>
                            <h2 className="text-[20px] font-bold mb-4">綁定官方平台</h2>
                            <div className="bg-[#F7F7F7] p-4 rounded-lg">
                                {platforms.map((platform, index) => (
                                    <div className="flex items-center gap-4 mb-4" key={platform.id}>
                                        <div className="flex-grow grid grid-cols-2 gap-4">
                                            <div>
                                                <div className="text-sm mb-1">平台</div>
                                                <Select
                                                    placeholder="請選擇平台"
                                                    className="w-full"
                                                    value={platform.type}
                                                    onChange={(value) => {
                                                        const updatedPlatforms = [...platforms];
                                                        updatedPlatforms[index].type = value;
                                                        setPlatforms(updatedPlatforms);
                                                    }}
                                                    options={[
                                                        { value: 'Facebook', label: 'Facebook' },
                                                        { value: 'Instagram', label: 'Instagram' },
                                                        { value: 'Youtube', label: 'Youtube' },
                                                        { value: 'Tiktok', label: 'Tiktok' }
                                                    ]}
                                                />
                                            </div>
                                            <div>
                                                <div className="text-sm mb-1">連結址</div>
                                                <Input
                                                    placeholder="http://example.com"
                                                    value={platform.url}
                                                    onChange={(e) => {
                                                        const updatedPlatforms = [...platforms];
                                                        updatedPlatforms[index].url = e.target.value;
                                                        setPlatforms(updatedPlatforms);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <Button
                                            type="text"
                                            danger
                                            icon={<CloseOutlined />}
                                            onClick={() => {
                                                const updatedPlatforms = platforms.filter((_, i) => i !== index);
                                                setPlatforms(updatedPlatforms);
                                            }}
                                        />
                                    </div>
                                ))}
                                <Button
                                    type="dashed"
                                    className="mt-4 text-[#67BE5F] border-[#67BE5F]"
                                    icon={<PlusOutlined />}
                                    onClick={() => {
                                        setPlatforms([...platforms, { id: Date.now().toString(), type: "", url: "" }]);
                                    }}
                                >
                                    新增官方平台
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Right Preview Area */}
            <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
                <Preview
                    data={{
                        name: '未設定',
                        provider: '店家',
                        price: 0,
                        description: '',
                        serviceNotice: '',
                        companyDescription: serviceDescription,
                        logo: logo || undefined,
                        companyName,
                        bannerImages,
                        mobileContentImages,
                        videoUrl,
                        partners,
                        socialLinks: getSocialLinks(platforms),
                    }}
                    onUpdateImage={handleUpdateImage}
                />
            </div>
        </div>
    );
};

export default Management;
