import axios from 'axios';
import { getAuthConfig, getOfficialAuthConfig } from '@/app/ams/services/api';

const Official = process.env.REACT_APP_E_OFFICIAL_SITE_MODULE === 'true';
const callApi = axios.create({
  timeout: 200000,
});

callApi.interceptors.request.use(async (config) => {
  try {
    let authConfig;
    if(Official) {
     authConfig = await getOfficialAuthConfig();
    } else {
     authConfig = await getAuthConfig();
    }
    config.baseURL = authConfig.serverUrl;
    config.headers.Authorization = `Bearer ${authConfig.accessToken}`;
    return config;
  } catch (error) {
    return Promise.reject(error);
  }
}, (error) => {
  return Promise.reject(error);
});

callApi.interceptors.response.use((response) => {
  response.data.status = response.status;
  return response;
}, (error) => {
  if (error.response) {
    error.response.data.status = error.response.status;
    return Promise.reject(error.response.data);
  }
  return Promise.reject(error);
});

export default callApi;
