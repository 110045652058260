import React from 'react';
import { Button, Tabs } from 'antd';
import detailConfig from '../config/detail/detailConfig';

export default function Detail({ data, type = 'service' }) {
  const config = detailConfig?.labels || {};

  if (!data) {
    return (
      <div className="w-full flex items-center justify-center h-[calc(100vh-64px-32px)] bg-gray-50">
        <div className="flex flex-col items-center justify-center">
          <p className="text-gray-500 text-center">
            {type === 'user' ? config.selectUser : config.selectService}
          </p>
        </div>
      </div>
    );
  }

  const items = [
    {
      key: "1",
      label: config.info,
      children: (
        <div className="flex-1 pb-20">
          <div className="flex flex-col bg-white">
            {/* 圖片區域 */}
            <div className="relative">
              <img 
                src={data.imageUrl} 
                alt={data.name} 
                className="w-full h-[174px] object-contain"
              />
              {type === 'service' && data.recommendation && (
                <div className="absolute top-4 left-4 bg-[#FF7171] text-white px-3 py-1 rounded-md">
                  {config.popular}
                </div>
              )}
            </div>

            <div className="p-6 flex flex-col gap-2">
              {/* 標題區域 */}
              <h1 className="text-2xl font-bold text-center">
                {data.name}
                {/* <span className="text-gray-400 ml-2 text-[18px]">
                  {data.gender ? (
                    data.gender === 'male' ? '先生' : data.gender === 'female' ? '女士' : '先生/女士'
                  ) : '先生/女士'}
                </span> */}
              </h1>

              {/* 功能標籤 - 只在服務類型時顯示 */}
              {type === 'service' && (
                <div className="flex gap-2">
                  <div className="bg-[#F3E5D4] text-[#DFB782] px-2 py-1 rounded-md">
                    {config.instantUse}
                  </div>
                  <div className="bg-[#F3E5D4] text-[#DFB782] px-2 py-1 rounded-md">
                    {config.freeCancellation}
                  </div>
                </div>
              )}

              {/* 描述資訊 */}
              <div>
                <h3 className="text-lg font-bold">{type === 'user' ? config.userInfo : config.serviceDesc}</h3>
                <p className="text-gray-700 whitespace-pre-line">{data.description}</p>
              </div>

              {/* 注意事項/其他資訊 */}
              <div>
                <h3 className="text-lg font-bold">{type === 'user' ? config.otherInfo : config.notice}</h3>
                <p className="text-gray-700 whitespace-pre-line">{data.notification}</p>
              </div>

              {/* 訂單資訊/規格選擇 */}
              {data.specs && (
                <div>
                  <h3 className="text-gray-400 mb-2">{type === 'user' ? config.orderInfo : config.specSelection}</h3>
                  <div className="flex gap-4">
                    {data.specs.map((spec, index) => (
                      <button key={index} className="bg-gray-100 px-6 py-3 rounded-lg">
                        {spec.name}: {spec.price}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>

            {/* 編輯按鈕 */}
            <div className="fixed w-[440px] bottom-0 bg-white border shadow-3xl p-4">
              <Button
                type="primary"
                className="w-full h-10 bg-[#ADDEAC] hover:bg-[#45a049] text-black hover:!text-black"
              >
                {config.edit}
              </Button>
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="w-full flex flex-col h-full bg-white overflow-auto">
      <Tabs defaultActiveKey="1" items={items} className="flex-1 [&_.ant-tabs-content-holder]:overflow-auto [&_.ant-tabs-nav]:px-4 [&_.ant-tabs-nav]:pt-4" />
    </div>
  );
};